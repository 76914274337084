export default {
    'mainTitle'                         : 'Merchant Account Creation {productName}',
    'stepTitle'                         : 'Step {step} of 5',

    'redirectNotice'                    : 'You will be redirected in a moment.',
    'manualRedirect'                    : 'Click here to go back to {productName}',
    'return_to_referer'                 : 'Go back to {productName}',

    'ACCOUNT_NUMBER_HELPER'             : '5 to 12 numbers',
    'TRANSIT_NUMBER_HELPER'             : '5 numbers',
    'INSTITUTION_ID_HELPER'             : '3 numbers',
    '1_7_DAYS'                           : '1 to 7 days',
    '8_14_DAYS'                          : '8 to 14 days',
    '15_DAYS_MORE'                       : '15 days or more',
    'ACCOUNT_NUMBER'                     : 'Account Number',
    'ACTIVATE'                           : 'Activate',
    'ACTIVATE_TEXT'                      : 'The creation of your account is almost finished, click on "Activate" to start the bank approval process. This process can take 24-72 hours.',
    'ACTIVATE_TEXT_TSI'                  : 'The creation of your account is almost finished, Select your billing option and click on "Activate" to start the bank approval process. This process can take 24-72 hours.',
    'ANSWER'                             : 'Answer',
    'BACK_TO_LIST'                       : 'Back to accounts list',
    'BRITISH_COLUMBIA'                   : 'British Columbia',
    'BUSINESS_CAT'                       : 'Business / Organization category',
    'BUSINESS_DESC'                      : 'Description',
    'BUSINESS_DESC_EX'                   : 'ex : Sport Organization',
    'BUSINESS_TYPE'                      : 'Enterprise / Organization type',
    'CHARITY'                            : 'Charity',
    'CIC'                                : 'Community Interest Company',
    'CITY'                               : 'City',
    'CLOSE_WINDOW'                       : 'Close the window',
    'CORP'                               : 'Corporation',
    'COUNTRY'                            : 'Country',
    'CONTACT_SPORDLE'                    : 'The security system has detected that you should not have access to create an account for this organization. If you believe this to be an error contact Spordle support.',
    'DATE_FORMAT'                        : 'YYYY-MM-DD',
    'DATE_OF_BIRTH'                      : 'Date of Birth',
    'DELIVERY_TIME_RANGE'                : 'If yes, how long does the shipping usually take?',
    'DIRECT_DEBIT'                       : 'Direct Debit',
    'DIRECT_VISA_DEBIT'                  : 'Direct Debit and Visa Debit',
    'DYNAMIC_DESC'                       : 'Description (between 0 et 20 letters)',
    'EDUCATION'                          : 'Education',
    'EMAIL'                              : 'Email address',
    'EN'                                 : 'English',
    'END_PROCESS'                        : 'Done',
    'ERROR_DIALOG_CONTENT_MESSAGE'       : '<div class="small-form-group"> An error has occured while saving. </div><div class="small-form-group">If you are unable to find the mistake, please contact us at <b>support@spordle.com</b>. </div><div class="small-form-group">Please give us your <b>last name</b>, <b>first name</b>, <b>phone number</b> as well as the <b>Organization</b> for which you are trying to create this account.To fasten the support query, you can also send us screenshots of the error messages you have received.</div><div class="small-form-group">Thank you,</div><div class="small-form-group">&nbsp; </div><div class="small-form-group">Here are the error codes returned. </div>',
    'E_ACCOUNT_CREATION'                 : 'Error while creating the merchant account',
    'E_ACTIVATE_TEXT'                    : 'The account activation request you have submitted seems to be invalid or contains errors, please contact Spordle.',
    'E_ACTIVATE_TITLE'                   : 'Activation error.',
    'E_BANK_ACCOUNT'                     : 'Error while saving the bank informations',
    'E_BUSINESS_OWNER'                   : 'Error while saving the business owner informations',
    'E_CANT_REACH_TEXT'                  : 'The connexion with Paysafe could not be established. Please try again later. For more information, please contact Spordle.',
    'E_CANT_REACH_TITLE'                 : 'Connection error.',
    'E_CREATE_USER'                      : 'Error while creating the user',
    'E_TERMS_AND_COND'                   : 'Error while accepting the Terms and Conditions',
    'E_required_field'                   : '%s is required.',
    'E_max_lenght'                       : '{field} must have maximum {param} characters.',
    'E_less_than'                        : '{field} must be less than {param}.',
    'E_numeric'                          : '{field} must contain only numbers.',
    'E_numeric_max_lenght'               : '{field} must contain only numbers and must have maximum {param} characters.',
    'FEDERAL_TAX_NUMBER'                 : 'Federal Tax Number',
    'FEDERAL_TAX_NUMBER_PLACEHOLDER'     : '123456789',
    'FIELD'                              : 'Field(s)',
    'FILL_REQUIRED_FIELDS'               : 'Please make sure to fill in all the required fields.',
    'FIRST_NAME'                         : 'First Name',
    'FR'                                 : 'French',
    'GENERIC_ERROR_MESSAGE'              : '<div class="small-form-group">An error occurred while saving the data on Paysafe.</div><div class="small-form-group">If you can\'t find the mistake, please contact us at <b>support@spordle.com</b>.</div><div class="small-form-group">If you contact us, please give us your <b>name</b>, <b>last name</b>, <b>phone #</b> and the <b>Organization name</b> of the Organization you are trying to create an account.To accelerate the process, you can also send us a screen shot of the errors shown.</div><div class="small-form-group">Thank you,</div><div class="small-form-group">&nbsp;</div><div class="small-form-group">Here are the error code(s) returned.</div>',
    'HAS_PROCESSED_B4'                   : 'Have you ever managed credit card transactions?',
    'HIGH'                               : 'Between et 100 000$ and 250 000$',
    'INSTITUTION_ID'                     : 'Institution Id',
    'JOB_TITLE'                          : 'Role in the Enterprise / Organization',
    'LANG_CHOICE'                        : 'Language choice',
    'LAST_NAME'                          : 'Last Name',
    'LEGAL_ENTITY'                       : 'Business / Organization legal name',
    'LEGEND_BANK_ACCOUNT_DETAILS'        : 'Bank Information',
    'LEGEND_BASIC'                       : 'Basic information for the Merchant Account',
    'LEGEND_BUSINESS_OWNER'              : 'Information on the Person in Charge',
    'LEGEND_BUSINESS_OWNER_CURR_ADDRESS' : 'Current Address of the Person in Charge',
    'LEGEND_BUSINESS_OWNER_PREV_ADDRESS' : 'Previous address of the Person in Charge',
    'LEGEND_CREATE_USER'                 : 'User Creation',
    'LEGEND_CREATE_USER_DESC'            : 'This user will have an administrator access to the Back Office on the Paysafe Platform.',
    'LEGEND_DETAILLED'                   : 'Detailled Account Information',
    'LEGEND_MERCHANT_ADDRESS'            : 'Merchant Address',
    'LEGEND_ON_TRANSAC'                  : 'Information shown on the "Credit Transaction"',
    'LEGEND_TERMS_AND_CONDITIONS'        : 'Terms and Conditions',
    'LOW'                                : 'Between 0 and 50 000$',
    'LTD'                                : 'Limited',
    'MEDIUM'                             : 'Between 50 000$ and 100 000$',
    'MEMBERSHIP_ORG'                     : 'Membership Organization',
    'MERCHANT_NAME'                      : 'Merchant Account Name',
    'NEWFOUNDLAND'                       : 'Newfoundland',
    'NEW_BRUNSWICK'                      : 'New Brunswick',
    'NEXT_STEP'                          : 'Next step',
    'NO'                                 : 'No',
    'NONE'                               : 'None',
    'NONE_OF_BOTH'                       : 'Neither',
    'NORTHWEST_TERRITORIES'              : 'North West Territories',
    'NOVA_SCOTIA'                        : 'Nova-Scotia',
    'NO_NUMBER_0'                        : 'If you do not have a number use 000000000',
    'NPCORP'                             : 'Non-Profit',
    'ORG_CHOICE'                         : 'Organization choice',
    'ORGANISATION_EMAIL'                 : 'Organization\'s Email',
    'VALID_EMAIL'                        : 'Please enter a valid email',
    'ORGANISATION_WEBSITE_URL'           : 'Organization\'s Website',
    'ORGANISATION_WEBSITE_URL_HELPER'    : 'ex: https://www.site.com',
    'PARTNERSHIP'                        : 'Partnership',
    'PASSWORD'                           : 'Password',
    'PERCENTAGE_OWNERSHIP'               : 'Percentage Ownership of the Applicant',
    'PHONE'                              : 'Phone Number',
    'PHONE_OWNER'                        : 'Phone number',
    'PHONE_SUPPORT'                      : 'Phone number (Customer support)',
    'PHONE_SUPPORT_HELPER'               : 'ex: 555 888-9999',
    'PRINCE_EDWARD_ISLAND'               : 'Prince Edward Island',
    'powred_by'                          : 'Powered by',
    'QUEBEC'                             : 'Quebec',
    'question_bank_account'              : 'What are the last four digits of your bank account number?',
    'question_bank_city'                 : 'In what city is your bank branch located?',
    'question_business_city_year'        : 'In what city and year was your business established? (e.g., Montreal 1999)',
    'question_first_car'                 : 'What is the make and model of the first car you owned?',
    'question_first_pet'                 : 'What is the name of your first pet?',
    'question_high_school_graduate'      : 'What high school did you graduate from, and in what year? (e.g., Central High 1999)',
    'question_mobile_provider'           : 'Who is your mobile provider, and what is your mobile number?',
    'question_oldest_sibling'            : 'What is the name of your oldest sibling?',
    'question_sum_birth'                 : 'What is the sum of your year of birth? (e.g., if YOB=1234, sum=1+2+3+4=10)',
    'question_youngest_sibling'          : 'What is the name of your youngest sibling?',
    'READ_AND_AGREE_TERMS'               : 'I have read and agree to the terms and conditions',
    'RECOVERY_QUESTION'                  : 'Secret Question',
    'RECREATION_SERVICES'                : 'Recreation Services',
    'REFUND_POLICY'                      : 'Do you have a refund policy?',
    'REFUND_POLICY_DESC'                 : 'If yes, can you describe it? ( maximum 255 characters )',
    'ADDITIONAL_PAYMENT_METHOD'          : 'Do you wish to apply for Visa and Mastercard Debit?',
    'ADDITIONAL_PAYMENT_METHOD_VISA_DEBIT': 'Visa / Debit',
    'REFUND_TERMS'                       : 'For security reasons, Paysafe accounts are setup to deposit the money in your bank account once a week, on friday night.',
    'REGION_CHOICE'                      : 'Region choice',
    'SECURITY_REASON'                    : 'Once a step is completed, you will not be able to go back, Spordle does not keep any information from this form for security reasons. Make sure the information is valid before proceeding to the next step.',
    'SELECT_ONE'                         : 'Select...',
    'SHIPS_GOODS'                        : 'Have you ever delivered goods?',
    'SOLE_PROP'                          : 'Sole Proprietorship',
    'SPECS_PASSWORD'                     : 'The password must contain at least 8 characters.\nMay contain letters and numbers.\nMust contain at least one uppercase and one lowercase.',
    'SPECS_PASSWORD1'                    : 'The password must contain at least 8 characters.',
    'SPECS_PASSWORD2'                    : 'May contain letters and numbers',
    'SPECS_PASSWORD3'                    : 'Must contain at least one uppercase and one lowercase.',
    'SPECS_USERNAME'                     : 'The username must be between 2 and 64 characters.\nIt may contain letters and numbers only.\nThe letters must all be in a lowercase format.\ncan not contain space.',
    'SPECS_USERNAME1'                    : 'The username must be between 2 and 64 characters.',
    'SPECS_USERNAME2'                    : 'It may contain letters and numbers only.',
    'SPECS_USERNAME3'                    : 'The letters must all be in a lowercase format.<br/>can not contain space.',
    'SPECS_USERNAME4'                    : 'May not contain spaces.',
    'SSN'                                : '*Social Security Number',
    'SSN_NOT_REQUIRED'                   : 'Social Security Number (not mandatory)',
    'STATE'                              : 'Province',
    'FORMIK_SELECT_NO_OPTION'            : 'No result',
    'STREET'                             : 'Address (Door number, street)',
    'STREET_2'                           : 'Address 2 (Apt. number, if necessary)',
    'SUMMARY_TEXT'                       : 'Thank you, the account creation is completed.',
    'TITLE'                              : 'Merchant Account Creation',
    'TRANSIT_NUMBER'                     : 'Transit Number',
    'UNDERSTAND'                         : 'I understand',
    'USER_NAME'                          : 'User name',
    'VERY_HIGH'                          : 'Over 250 000$',
    'VISA_DEBIT'                         : 'Visa Debit',
    'WARNING'                            : 'Warning',
    'WEBSITE_URL'                        : 'Website',
    'YEARLY_VOLUME_RANGE'                : 'Yearly volume range',
    'YEARS_AT_ADDRESS'                   : 'How many years have you lived at this address?',
    'YEARS_AT_ADDRESS_BEFORE'            : 'How many years have you lived at this address?',
    'YES'                                : 'Yes',

    //field
    'ZIP'                                : 'Postal Code',
    'ZIP_PLACEHOLDER'                    : 'H7H 7H7',
    'ref_id'                             : 'Organization reference Id',
    'product'                            : 'Product',
    'Merchant_name'                      : 'Merchant name',
    'legalEntity'                        : 'Legal name',
    'category'                           : 'Organization category',
    'phone'                              : 'Phone',
    'description'                        : 'Description',
    'org_type'                           : 'Organization type',
    'address'                            : 'Address',
    'city'                               : 'City',
    'zip'                                : 'Postal Code',
    'yearlyVolumeRange'                  : 'Yearly volume range',
    'dynamicDescriptor'                  : 'Credit Transaction description',
    'first_name'                         : 'First name',
    'last_name'                          : 'Last name',
    'job_title'                          : 'Role in the entreprise',
    'phone_owner'                        : 'Phone number',
    'date_of_birth'                      : 'Date of birth',
    'date_of_birth_helper'               : 'ex: YYYY-MM-DD',
    'street_owner'                       : 'Address',
    'zip_owner'                          : 'Postal code',
    'years_at_address'                   : 'This information',
    'percentage_ownership'               : 'Percentage Ownership',

    //fee absorbtion
    'FEE_SELECTION'                      : 'Type of billing.',
    'CLIENT_ABSORB'                      : 'The entire fees (Processing and Technology service fees) are added on top of the registration fee, meaning it\'s paid by the player registration. (These amounts are considered service fees on the buyers’ bill.)',
    'ORG_ABSORB'                         : 'Absorb the full fees (Processing and Technology service fees) within his cost.',
    'SPLIT_ABSORB'                       : 'Absorb only the payment processing fee (2,45%) and the technology fee is added on top of the registration fee (paid by the player registration)',
}