export default{
    "footer.copyright" : "Powered by <a>Spordle</a> ©{date} Spordle Inc. All rights reserved.",
    "footer.terms"     : "Terms of use",
    "footer.terms.a"   : "terms",
    "footer.consent"   : "Consent",
    "footer.privacy"   : "Privacy Policy",
    "footer.privacy.a" : "privacy",
    "footer.ads"       : "Interest-Based Ads",
    "footer.about"     : "About",
    "footer.about.a"   : "about",
    "footer.solutions" : "Solutions",
};