import React, { useState, useEffect, useContext } from "react";
import { preparePayment } from "../api/client/payment";
import { AxiosIsCancelled } from "../api/CancellableAPI";
import { I18nContext } from "./I18nContext";
import { LOCALES } from "../i18n";

export const PaymentContext = React.createContext({});

const PaymentContextProvider = ({
    paymentToken,
    ...props
}) => {

    const { setLocale } = useContext(I18nContext);

    const [ isLoading, setLoading ] = useState(true);
    const [ error, setError ] = useState(true);

    const [ currentStep, setCurrentStep ] = useState(0);
    const [ completedSteps, setCompletedSteps ] = useState();
    const previousStep = () => {
        if(currentStep > 1)
            setCurrentStep(currentStep - 1)
    }
    const nextStep = () => {
        if(currentStep < steps.length + 1){
            if(completedSteps){
                setCurrentStep(4);
            }else{
                setCurrentStep(currentStep + 1);
            }
        }
    }


    const [ paymentInfo, setPaymentInfo ] = useState(null);
    const [ _paysafeTokenized, setPaysafeTokenized ] = useState(false);
    const [ invalidPaysafeToken, setInvalidPaysafeToken ] = useState(false);

    const [ billingInfo, setBillingInfo ] = useState(null);
    const [ hasBillingError, setHasBillingError ] = useState(false);
    const [ hasCardError, setHasCardError ] = useState(false);

    let paysafeTokenTimeout = null;

    const _setPaysafeToken = () => {
        setPaysafeTokenized(true)

        // Set timeout for 5 minutes (a little less to ensure no delays anywhere cause an invalide payment)
        paysafeTokenTimeout = setTimeout(() => {
            setPaysafeTokenized(false);
        }, 4.5 * 60 * 1000);
    }

    const triggerInvalidPaysafeToken = () => {
        setInvalidPaysafeToken(true);
        setCurrentStep(3);
    }

    const initializePayment = (paymentToken) => {
        preparePayment(paymentToken)
            .then((payment_info) => {
                if(payment_info && !Array.isArray(payment_info)){
                    const newPaymentInfo = payment_info;
                    newPaymentInfo.cart_info = payment_info.cart_info ? JSON.parse(payment_info.cart_info) : null;
                    newPaymentInfo.tax_info = payment_info.tax_info ? JSON.parse(payment_info.tax_info) : null;
                    newPaymentInfo.fee_info = payment_info.fee_info ? JSON.parse(payment_info.fee_info) : null;
                    newPaymentInfo.redirection_info = payment_info.redirection_info ? JSON.parse(payment_info.redirection_info) : null;
                    setPaymentInfo(newPaymentInfo);

                    if(newPaymentInfo.cart_info?.language){
                        setLocale((newPaymentInfo.cart_info.language == "en" || newPaymentInfo.cart_info.language == "en-ca") ? LOCALES.ENGLISH : LOCALES.FRENCH);
                    }

                    if(newPaymentInfo.status == 'COMPLETED')
                        setError("6002")
                    else if(newPaymentInfo.status == 'CANCELED' || newPaymentInfo.status == 'CANCELLED')
                        setError("6004")
                    else if(newPaymentInfo.status == 'EXPIRED' || (new Date(newPaymentInfo.expiration_date) < new Date()))
                        setError("6005")
                }else{
                    setError("6003");
                }
                setLoading(false);
                setCurrentStep(1);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setError(error.message);
                    setLoading(false);
                }
            });
    }

    const steps = [
        {
            en: 'Cart',
            fr: 'Panier',
        },
        {
            en: 'Billing',
            fr: 'Facturation',
        },
        {
            en: 'Payment',
            fr: 'Paiement',
        },
        {
            en: 'Checkout',
            fr: 'Vérification',
        },
    ];

    /***********************************************************************************************************************
    /* Listener for the alert before leaving the page
    /***********************************************************************************************************************/
    const addBeforeUnloadListener = () => {
        window.onbeforeunload = function(){
            return "Attention!"; // Text is decided by browser
        };
    }

    const removeBeforeUnloadListener = () => {
        window.onbeforeunload = function(){};// Will overwrite
    }


    /***********************************************************************************************************************
    /* UseEffect - mount / unmount
    /***********************************************************************************************************************/
    useEffect(() => {
        if(paymentToken){
            initializePayment(paymentToken);
        }
    }, [ paymentToken ])

    useEffect(() => {
        if(currentStep > 1){
            addBeforeUnloadListener();
        }

        return () => {
            clearTimeout(paysafeTokenTimeout);
            removeBeforeUnloadListener();
        }
    }, [ currentStep ])


    return (
        <PaymentContext.Provider
            value={{
                isLoading,
                error,
                currentStep,
                completedSteps,
                paymentInfo,
                billingInfo,
                steps,
                hasBillingError,
                hasCardError,
                invalidPaysafeToken,
                setHasBillingError,
                setHasCardError,
                setBillingInfo,
                setLoading,
                setError,
                setCurrentStep,
                previousStep,
                nextStep,
                triggerInvalidPaysafeToken,
                setCompletedSteps,
                setInvalidPaysafeToken,
            }}
        >
            {props.children}
        </PaymentContext.Provider>
    );

}

export default PaymentContextProvider;
