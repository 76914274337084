export default {
    'error.required'                    : 'Ce champ est requis.',
    'error.invalidEmail'                : '{email} n\'est pas un courriel valide.',
    'error.minLength'                   : 'Le nombre minimum de charactères est de {min}.',
    'error.maxLength'                   : 'Le nombre minimum de charactères est de {max}.',
    'error.isNumeric'                   : 'Peut contenir que des chiffres.',
    'error.isAlpha'                     : 'Peut contenir que des lettres.',
    'error.isLower'                     : 'Les lettres doivent tous être en minuscule.',
    'error.isUpper'                     : 'Les lettres doivent tous être en majuscule.',
    'error.noSpaces'                    : 'Ne peut pas contenir d\'espace.',
    'error.length'                      : 'Ce champ doit contenir {length} charactères.',
    'error.length.min'                  : 'Doit contenir un minimum de {min} caractères.',
    'error.length.max'                  : 'Doit contenir un maximum de {max} caractères.',
    'error.email'                       : 'Veuillez entrer un courriel valide',
    'error.url'                         : 'Veuillez saisir une URL valide',
    'error.phoneFormat'                 : 'Please enter a phone number',
    'error.zipFormat'                   : 'Veuillez saisir un format zip valide',
    'error.dateFormat'                  : 'Veuillez entrer une date valide',
    'error.max'                         : 'La valeur maximale est {max}',
    'error.min'                         : 'La valeur minimale est {min}',
    'error.oneMaj'                      : 'Ce champ doit contenir au moins une lettre majuscule',
    'error.oneMin'                      : 'Ce champ doit contenir au moins une lettre minuscule',
    'error.birth.minAge'                : 'Vous devez avoir {age} ans ou plus',
    'error.merchantName.inUse'          : 'Ce nom de marchant est déjà utilisé',

    'error.api'                         : 'Une erreur s\'est produite. Veuillez contacter le support technique.',
    'error.api.step2.general'           : 'Une erreur s\'est produite. Veuillez contacter le support technique.',
    'error.api.step2.server '           : 'Une erreur serveur s\'est produite. Veuillez contacter le support technique.',
    'error.api.step2.5068'              : 'Veuillez vérifier les informations des champs. Un ou plusieurs champs sont incorrects.',
}