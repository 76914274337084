import { useContext, useEffect, useState } from "react";
import {
    Fade,
    Collapse,
    Spinner,
    Alert
} from "reactstrap";


import { I18nContext } from '../../../contexts/I18nContext';
import { MiniGatewayContext } from "@spordle/mini-gateway";
import Translate from "@spordle/intl-elements";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { AxiosCancelAll } from "../../../api/CancellableAPI";

const PaymentConfirmation = () => {
    const gateway = useContext(MiniGatewayContext);
    const { getGenericLocale } = useContext(I18nContext);
    const {
        paymentInfo,
        setCurrentStep,
        steps,
        setError,
        triggerInvalidPaysafeToken,
        setHasBillingError,
        setHasCardError,
    } = useContext(PaymentContext);

    const [ showConfirmationLoading, setShowConfirmationLoading ] = useState(true);
    const [ showError, setShowError ] = useState(false);
    const [ confirmationId, setConfirmationId ] = useState(false);

    const processPayment = () => {
        if(gateway.paysafeTokenIsValid()){
            gateway.processPayment()
                .then((data) => {
                    if(paymentInfo.redirection_auto === true || paymentInfo.redirection_auto === "1"){
                        window.onbeforeunload = function(){ // Will overwrite
                        };
                        setTimeout(() => {
                            window.location.href = paymentInfo.redirection_url;
                        }, 4000);
                    }
                    setShowConfirmationLoading(false)
                    setConfirmationId(data.returned?.paysafe_response?.merchantRefNum)
                }, (error) => {
                    if(!error.wasCancelled){
                        if(error.gateway){
                            setError(error.code)
                            // showConfirmationError(`payment.error.title`, `payment.gateway.error.${error.code}`, true, true); // Will be translated
                        }else if(error.generic){
                            showConfirmationError(`payment.error.title`, 'payment.paysafe.processing.error.generic', true, true); // Will be translated
                        }else{
                            showConfirmationError(`payment.error.title`, `payment.paysafe.processing.error.${error.code}`, true, true); // Will be translated
                        }
                    }
                });
        }else{
            triggerInvalidPaysafeToken();
        }
    }

    useEffect(() => {
        processPayment();
        return () => {
            AxiosCancelAll();
        }
    }, [])

    const showConfirmationError = (title, text, hasBillingError, hasCardError) => {
        setCurrentStep(steps.length); // Go to before last step: review
        setHasBillingError(hasBillingError);
        setHasCardError(hasCardError);
        setShowError(text);
    }

    return (
        <Collapse isOpen className="py-5 px-3">
            <Fade in={showConfirmationLoading} unmountOnExit>
                <div className="text-center">
                    <div className="mb-4 text-success">
                        <Spinner color="secondary" style={{ width: '80px', height: '80px' }} type="grow" role="status" />
                    </div>
                    <div className="mb-3">
                        <h1 className="h3 font-medium"><Translate id="paymentDone.proccess.title" /></h1>
                        <p className="px-4"><Translate id="paymentDone.proccess.description" /></p>
                    </div>
                </div>
            </Fade>

            <Fade in={showError} unmountOnExit>
                <Alert color="danger">
                    <>{showError}</>
                </Alert>
            </Fade>

            <Fade in={!showConfirmationLoading}>
                {(!showConfirmationLoading) ?
                    <div className="text-center">
                        <div className="mb-4 text-success">
                            <i className="far fa-check-circle" style={{ fontSize: 100 }} />
                        </div>
                        <div className="mb-3">
                            <h1 className="h3 font-medium"><Translate id="paymentDone.success.title" /></h1>
                            <p className="px-4"><Translate id="paymentDone.success.description" /> <a href={`mailto:${gateway.formik.values.email}`}>{gateway.formik.values.email}</a></p>
                        </div>

                        <div className="mb-4">
                            <div className="h5 font-medium mb-1"><Translate id="paymentDone.success.billing" /></div>
                            <div>
                                #{confirmationId}
                            </div>
                        </div>

                        {(paymentInfo.redirection_auto === true || paymentInfo.redirection_auto === "1") ?
                            <div className="text-dark mb-4">
                                <Translate id="paymentDone.success.autoRedirect" />
                            </div>
                            : paymentInfo.redirection_url ?
                                <div className="mb-3">
                                    <a href={paymentInfo.redirection_url} className="btn btn-info" target="_blank" rel="noopener noreferrer">
                                        {paymentInfo.redirection_info?.[0] && paymentInfo.redirection_info[0].button && paymentInfo.redirection_info[0].button[getGenericLocale()] ?
                                            paymentInfo.redirection_info[0].button[getGenericLocale()]
                                            :
                                            <Translate id={`paymentDone.success.action.${paymentInfo.product}`} />
                                        }
                                        <i className="mdi mdi-launch ml-1" />
                                    </a>
                                </div>
                                : ''}
                    </div>
                    : ''}
            </Fade>
        </Collapse>
    );
}

export default PaymentConfirmation;