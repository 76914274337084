import { useContext, useEffect, useState } from "react";
import {
    CardBody,
    Button,
    Fade
} from "reactstrap";
import Translate from "@spordle/intl-elements";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { asArray } from "../../../helpers/helpers";

const PaymentPreview = (props) => {
    const { paymentInfo, nextStep } = useContext(PaymentContext);
    const hasSubtotal = paymentInfo.amount_before_tax_fee !== paymentInfo.total_amount;
    const totalTaxAmount = asArray(paymentInfo.tax_info).reduce((totalTax, tax) => { return totalTax + parseInt(tax.amount) }, 0)
    const organisationAbsorbsFee = (parseInt(paymentInfo.amount_before_tax_fee) + parseInt(totalTaxAmount)) === parseInt(paymentInfo.total_amount);
    const [ showSpordleImg, setShowSpordleImg ] = useState(!paymentInfo?.logo_url);

    useEffect(() => {
        setShowSpordleImg(!paymentInfo?.logo_url);
    }, [ paymentInfo?.logo_url ])

    return (
        <Fade>
            <CardBody>
                {(paymentInfo) ?
                    <>
                        <div className="text-center">
                            <img
                                src={showSpordleImg ? 'https://www.spordle.com/img/favicon/favicon.ico' : paymentInfo.logo_url}
                                onError={() => {
                                    setShowSpordleImg(true);
                                }}
                                alt="user"
                                className="rounded-circle my-4 shadow"
                                width="112"
                            />
                            {paymentInfo && paymentInfo.title ? <div className="h3 mb-1 font-bold">{paymentInfo.title}</div> : ''}
                            {paymentInfo && paymentInfo.transaction_id ? <div className="mb-1">#{paymentInfo.transaction_id}</div> : ''}
                        </div>
                        {paymentInfo && paymentInfo.description ? <div className="mt-1" dangerouslySetInnerHTML={{ __html: paymentInfo.description }} /> : ''}
                    </>
                    :
                    null
                }

                <hr className='mx-5 my-4' />

                <div className="d-flex justify-content-center pr-3 w-100 ">
                    <div className="d-table text-right">
                        {hasSubtotal &&
                            <div className="d-table-row">
                                <div className="d-table-cell pl-2"><Translate id="paymentPreviewDetails.title.subTotal" /> :</div>
                                <div className="d-table-cell pl-2"><Translate id="misc.priceValue" values={{ price: (paymentInfo.amount_before_tax_fee / 100).toFixed(2) }} /></div>
                            </div>
                        }
                        {(paymentInfo.tax_info && Array.isArray(paymentInfo.tax_info)) ? paymentInfo.tax_info.map((tax, index) => {
                            return (
                                <div key={tax.name} className="d-table-row">
                                    <div className="d-table-cell pl-2">{tax.name} :</div>
                                    <div className="d-table-cell pl-2"><Translate id="misc.priceValue" values={{ price: (tax.amount / 100).toFixed(2) }} /></div>
                                </div>
                            )
                        }) : ''}
                        {(!organisationAbsorbsFee && paymentInfo.spordle_fee && paymentInfo.spordle_fee > 0) &&
                            <div className="d-table-row">
                                <div className="d-table-cell pl-2"><Translate id="paymentPreviewDetails.title.fee" /> :</div>
                                <div className="d-table-cell pl-2"><Translate id="misc.priceValue" values={{ price: (paymentInfo.spordle_fee / 100).toFixed(2) }} /></div>
                            </div>
                        }
                        <div className="d-table-row text-dark font-bold h3">
                            <div className="d-table-cell pl-2 pt-2"><Translate id="paymentPreviewDetails.title.total" /> :</div>
                            <div className="d-table-cell pl-2 pt-2"><Translate id="misc.priceValue" values={{ price: (paymentInfo.total_amount / 100).toFixed(2) }} /></div>
                        </div>
                    </div>
                </div>

                <div className="mt-5 d-flex justify-content-center">
                    <Button color="success" onClick={nextStep} className="w-100" size="lg"><Translate id="payment.label.sendPayment" /></Button>
                </div>
            </CardBody>
        </Fade>
    )
}

export default PaymentPreview;