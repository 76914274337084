import { Component, createContext } from 'react';
import { getUrlParameter } from '../helpers/urlHelpers';
import { LOCALES } from '../i18n';

export const I18nContext = createContext();

export const getGenericLocale = (specificLang) => {
    switch (specificLang){
        case 'en-ca':
            return 'en';
        default: // This is valid for default language (fr) and language that is not available
            return 'fr';
    }
}

class I18nContextProvider extends Component{
    constructor(props){
        super(props)
        // sometimes, lang would be set to "undefined", this fixes that
        // not the best solution, but it'll do for now
        if(localStorage.getItem('lang') === "undefined"){
            localStorage.removeItem('lang')
        }else{
            this.state = {
                locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr-ca',
            }
        }
    }

    getFullLanguage = () => {
        switch (this.state.locale){
            case 'en-ca':
                return 'English';
            default: // This is valid for default language (fr-ca) and language that is not available
                return 'Français';
        }
    }

    getGenericLocale = () => getGenericLocale(this.state.locale);

    setLocale = (locale) => {
        localStorage.setItem('lang', locale);
        this.setState({ locale: locale });
    }

    componentDidMount(){
        const initLang = getUrlParameter('lang') ?? null;
        if(initLang){
            if(initLang === 'fr')
                this.setLocale(LOCALES.FRENCH);
            else
                this.setLocale(LOCALES.ENGLISH);
        }
    }

    render(){
        return (
            <I18nContext.Provider value={{
                ...this.state,
                setLocale: this.setLocale,
                getLanguageName: this.getFullLanguage,
                getGenericLocale: this.getGenericLocale,
            }}
            >
                {this.props.children}
            </I18nContext.Provider>
        );
    }
}
export default I18nContextProvider;