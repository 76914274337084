import React from 'react';
import {
    Button,
    Row,
    Container,
    Col
} from 'reactstrap';
import { LOCALES } from '../../i18n';
import { I18nContext } from '../../contexts/I18nContext';
import Translate from '@spordle/intl-elements';
import APIGatewayAccount from '../../api/APIGatewayAccount'
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import Footer from '../../layouts/layout-components/footer/footer';

// import cvvImage from '../../assets/images/cvv.png';

class Home extends React.Component{
    static contextType = I18nContext;

    testAccountCreation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const target = e.target
        target.disabled = true;

        const ref_id = document.getElementById('test_ref').value;
        const product = document.getElementById('test_product').value;

        APIGatewayAccount.post("getAccessToken", { "product": product, "ref_id": ref_id })
            .then((response) => {
                //handle success
                var data = response.data;
                if(data.status){
                    this.props.history.push(`/accountCreation?lang=fr&product=${product}&ref_id=${ref_id}&tok=${data.token}`);
                }else{
                    // Managed error
                    console.error("oops");
                    target.disabled = false;
                }
            })
            .catch((response) => {
                //handle error
                target.disabled = false;
                if(!AxiosIsCancelled(response.message)){
                    console.error(response);
                    this.props.history.push('/home');
                }
            });
    }

    testPayment = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target
        target.disabled = true;

        const paymentId = document.getElementById('paymentId').value;

        // Redirect wth dummy data
        this.props.history.push(`/payment/${paymentId}`);

    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                <I18nContext.Consumer>
                    {(i18n) => (
                        <div className="auth-wrapper d-flex flex-column no-block align-items-center bg-light">
                            <div className="p-3 position-absolute" style={{ top: 0, right: 0 }}>
                                {(i18n.locale === 'en-ca') ?
                                    <Button color="link" onClick={() => i18n.setLocale(LOCALES.FRENCH)}>FR</Button>
                                    :
                                    <Button color="link" onClick={() => i18n.setLocale(LOCALES.ENGLISH)}>EN</Button>
                                }
                            </div>

                            <div className="text-center mt-5 mb-5">
                                <h1 className="text-dark font-bold h3 mt-5"><Translate id="home.title" /></h1>
                                <h1 className="text-dark h4 mt-2"><Translate id="home.subtitle" /></h1>
                            </div>

                            <Container className="ml-2 mr-2 mb-5">
                                <Row className="text-center">
                                    <Col md='4'>
                                        <a href="https://www.spordle.com/" rel="noopener noreferrer" className="card btn" style={{ cursor: "pointer" }}>
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <div className="w-100 mt-4 mb-4">
                                                        <i className="mdi mdi-account-card-details text-dark" style={{ fontSize: "40px" }} />
                                                    </div>
                                                    <div className="h4 font-bold text-dark"><Translate id="home.merchant" /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </Col>
                                    <Col md='4'>
                                        <a href="https://www.spordle.com/" rel="noopener noreferrer" className="card btn" style={{ cursor: "pointer" }}>
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <div className="w-100 mt-4 mb-4">
                                                        <i className="mdi mdi-credit-card-scan text-dark" style={{ fontSize: "40px" }} />
                                                    </div>
                                                    <div className="h4 font-bold text-dark"><Translate id="home.payment" /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </Col>
                                    <Col md='4'>
                                        <a href="https://www.spordle.com/" rel="noopener noreferrer" className="card btn" style={{ cursor: "pointer" }}>
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <div className="w-100 mt-4 mb-4">
                                                        <i className="mdi mdi-bank text-dark" style={{ fontSize: "40px" }} />
                                                    </div>
                                                    <div className="h4 font-bold text-dark"><Translate id="home.vault" /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </Col>
                                    {(process.env.REACT_APP_ENVIRONMENT !== "prod") ?
                                        <>
                                            <Col md='12 mt-5 mb-5'>
                                                <div className="card" style={{ border: '1px solid red' }}>
                                                    <div className="card-body">
                                                        <Row>
                                                            <Col md='4'>
                                                                <input className="ml-2 mr-2 w-100 form-control" id="test_product" placeholder="Product [ENR, SID, TSI]" defaultValue="ENR" />
                                                            </Col>
                                                            <Col md='4'>
                                                                <input className="ml-2 mr-2 w-100 form-control" id="test_ref" placeholder="Ref" defaultValue={Math.floor(Math.random() * Math.floor(10000))} />
                                                            </Col>
                                                            <Col md='4'>
                                                                <button type="button" onClick={this.testAccountCreation} className="btn btn-dark w-100">TEST Merchant Account Creation</button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md='12'>
                                                <div className="card" style={{ border: '1px solid red' }}>
                                                    <div className="card-body">
                                                        <Row>
                                                            <Col md='8'>
                                                                <input className="ml-2 mr-2 w-100 form-control" id="paymentId" placeholder="Payment ID" />
                                                            </Col>
                                                            <Col md='4'>
                                                                <button type="button" onClick={this.testPayment} className="btn btn-dark w-100">TEST Payment</button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                        : null}
                                </Row>
                            </Container>

                        </div>
                    )}
                </I18nContext.Consumer>
                <Footer />
            </>
        );
    }
}

export default Home;
