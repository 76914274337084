import axios from 'axios';
import { activeCalls } from './CancellableAPI';

const CancelToken = axios.CancelToken;
const API_UTILS = axios.create({
    baseURL: "https://pub-proxy.hcr.spordle.com/v1",
    headers: {
        common: {
            'Content-Type': 'application/json',
            'Accept-Language': 'fr;en', // All supported languages
        },
        patch: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        put: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    },
});

API_UTILS.interceptors.request.use(function(config){
    config.cancelToken = new CancelToken(function executor(c){
        // An executor function receives a cancel function as a parameter
        activeCalls.push(c);
    });
    return config;
});

export default API_UTILS;