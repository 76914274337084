export default {
    'error.required'                    : 'This field is required.',
    'error.invalidEmail'                : '{email} is not a valid email.',
    'error.minLength'                   : 'Must be at least {min} characters long.',
    'error.maxLength'                   : 'Must have a maximum of {min} characters.',
    'error.isNumeric'                   : 'May only contain numbers.',
    'error.isAlpha'                     : 'May only contain letters.',
    'error.isLower'                     : 'Letters must be lower case.',
    'error.isUpper'                     : 'Letters must be upper case.',
    'error.noSpaces'                    : 'May not contain spaces.',
    'error.length'                      : 'Must be {length} characters long.',
    'error.length.min'                  : 'Must contain a minimum of {min} characters.',
    'error.length.max'                  : 'Must contain a maximum of {max} characters.',
    'error.email'                       : 'Please enter a valid email',
    'error.url'                         : 'Please enter a valid url',
    'error.phoneFormat'                 : 'Please enter a phone number',
    'error.zipFormat'                   : 'Please enter a valid zip format',
    'error.dateFormat'                  : 'Please enter a valid date',
    'error.max'                         : 'The maximum value is {max}',
    'error.min'                         : 'The minimum value is {min}',
    'error.oneMaj'                      : 'This field must have at least one upper case letter',
    'error.oneMin'                      : 'This field must have at least one lower case letter',
    'error.birth.minAge'                : 'You must be {age} years old or older',
    'error.merchantName.inUse'          : 'This merchant name is already in use',

    'error.api'                         : 'An error occured. Please contact the technical support.',
    'error.api.step2.general'           : 'An error occured. Please contact the technical support.',
    'error.api.step2.server'            : 'An server error occured. Please contact the technical support.',
    'error.api.step2.5068'              : 'Please verify the fields information. One or more field are incorrect.',
}