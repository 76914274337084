export default {
    'countries.CA.AB'                    : 'Alberta',
    'countries.CA.BC'                    : 'British',
    'countries.CA.MB'                    : 'Manitoba',
    'countries.CA.NB'                    : 'New Brunswick',
    'countries.CA.NL'                    : 'Newfoundland and Labrador',
    'countries.CA.NS'                    : 'Nova Scotia',
    'countries.CA.NU'                    : 'Nunavut',
    'countries.CA.NT'                    : 'Northwest Territories',
    'countries.CA.ON'                    : 'Ontario',
    'countries.CA.PE'                    : 'Prince Edward Island',
    'countries.CA.QC'                    : 'Quebec',
    'countries.CA.SK'                    : 'Saskatchewan',
    'countries.CA.YT'                    : 'Yukon',
}