import React from "react";
import {
	Button,
    Card,
    CardBody,
    Spinner
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Wrapper from "../../components/Wrapper";

// Translate function (i18n)
import Translate from '@spordle/intl-elements';

// Form
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import { FormikCheckedButton, FormikGroup } from "@spordle/formik-elements";

// API
import { AccountCreation } from "../../contexts/AccountCreationContext";
import { AxiosCancelAll, AxiosIsCancelled } from "../../api/CancellableAPI";

class AccountCreationStep4 extends React.Component {
    static contextType = AccountCreation;

    state = {
        dataReady: false,
        termsVersion: null
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        this.context.getTermsAndConditions(this.props.product, this.props.ref_id)
            .then((response) => {
                this.setState(() => ({dataReady: response.returned.terms_html, termsVersion: response.returned.version}));
            })
            .catch((response) => {
                console.log(response);
            });
    }

    componentWillUnmount(){
        AxiosCancelAll()
    }

    render() {
        const formatMessage = this.props.intl.formatMessage;
        return (
            /*--------------------------------------------------------------------------------*/
            /* Step 4                                                                         */
            /*--------------------------------------------------------------------------------*/
            <>
                <div className="h3 mt-2 mb-4 font-bold text-center"><Translate id="LEGEND_TERMS_AND_CONDITIONS"/></div>
                <Card className="border">
                    <CardBody>
                        <Card className="border rounded">
                            <CardBody>
                                <PerfectScrollbar>
                                    <div id="terms_and_conditions" className="m-4">
                                        {this.state.dataReady === false ?
                                            <div className="text-center">
                                                <Spinner color="info"/>
                                            </div>
                                        :
                                            <span dangerouslySetInnerHTML={{__html: this.state.dataReady}}/>
                                        }
                                    </div>
                                </PerfectScrollbar>
                            </CardBody>
                        </Card>

                        <Formik
                            initialValues={{
                                terms_and_condition: false,
                            }}
                            validationSchema={Yup.object().shape({
                                terms_and_condition: Yup.bool().test({
                                    name: 'terms_and_condition_required',
                                    message: formatMessage({id: 'error.required'}),
                                    test: (value) => value
                                }),
                            })}
                            onSubmit={(_values, { setSubmitting, setStatus }) => {
                                setStatus();
                                this.context.step4(this.state.termsVersion, this.props.product, this.props.ref_id)
                                    .then(() => {
                                        this.props.nextStep();
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            setSubmitting(false);
                                            console.error(error);
                                            setStatus(error.message);
                                        }
                                    });
                            }}
                        >
                            {formik => (
                                <Form id='step4_form'>
                                    <div style={{marginLeft: 15}}> {/* remove this div when FormikGroup's row has ben removed */}
                                        <FormikGroup name='terms_and_condition'>
                                            <FormikCheckedButton name='terms_and_condition' id='terms_and_condition' label='READ_AND_AGREE_TERMS'/>
                                        </FormikGroup>
                                    </div>
                                    <div className="mt-4 text-right">
                                        <Button color="info" type="submit" disabled={formik.isSubmitting || !formik.values.terms_and_condition || this.state.dataReady === false}><Translate id="NEXT_STEP"/></Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            </>
        );
    }
}
export default (props) => <Wrapper {...props} JsxElement={AccountCreationStep4}/>;