import { useContext, useEffect, useState } from "react";
import {
    Button,
    CardBody,
    ListGroup,
    ListGroupItem,
    Fade,
    Collapse
} from 'reactstrap';

import cardVisa from "../../../assets/images/VISA.png";
import cardMaster from "../../../assets/images/MasterCard.png";
import cardAmex from "../../../assets/images/AMEX.png";
import { MiniGatewayContext } from "@spordle/mini-gateway";
import Translate from "@spordle/intl-elements";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { asArray } from "../../../helpers/helpers";


const PaymentReview = ({ checkout }) => {
    const gateway = useContext(MiniGatewayContext);
    const {
        paymentInfo,
        setCurrentStep,
        setCompletedSteps,
        hasBillingError,
        hasCardError,
    } = useContext(PaymentContext);

    const [ collapseCart, setCollapseCart ] = useState(false);

    const hasSubtotal = paymentInfo.amount_before_tax_fee !== paymentInfo.total_amount;
    const totalTaxAmount = asArray(paymentInfo.tax_info).reduce((totalTax, tax) => { return totalTax + parseInt(tax.amount) }, 0)
    const organisationAbsorbsFee = (parseInt(paymentInfo.amount_before_tax_fee) + parseInt(totalTaxAmount)) === parseInt(paymentInfo.total_amount);

    useEffect(() => {
        setCompletedSteps(true);
    }, [ ]);

    const isSupportedCardImage = (cardName) => {
        switch (cardName){
            case "American Express":
            case "MasterCard":
            case "Visa":
                return true;
            default:
                return false;
        }
    }

    const getCardBrand = (cardName) => {
        switch (cardName){
            case "American Express":
                return cardAmex;
            case "MasterCard":
                return cardMaster;
            case "Visa":
                return cardVisa;
            default:
                return "";
        }
    }

    return (
        <Fade>
            <CardBody>
                <h1 className="h2 mb-3 font-bold "><Translate id="title.review.title" /></h1>

                <ListGroup>
                    <ListGroupItem action onClick={() => setCurrentStep(2)} className={`text-left mb-3 ${hasBillingError ? 'border-danger' : ''}`}>
                        <h4 className="ml-n2" style={{ textDecoration: "underline" }}><Translate id="paymentReview.billing.title" /></h4>
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className="text-dark font-medium">
                                    {gateway.formik.values.email}
                                </div>
                                <div className="font-medium">
                                    {gateway.formik.values.firstName} {gateway.formik.values.lastName}
                                </div>
                                <div className="small">
                                    {gateway.formik.values.addressStreet}, {gateway.formik.values.city }, {gateway.formik.values.province} {gateway.formik.values.country} - {gateway.formik.values.zip}
                                </div>
                            </div>
                            <div className="">
                                <button type="button" className="reset-btn text-link" onClick={() => setCurrentStep(2)}><i className="mdi mdi-pencil font-18" /></button>
                            </div>
                        </div>
                    </ListGroupItem>

                    <ListGroupItem action onClick={() => setCurrentStep(3)} className={`text-left mb-3 ${hasCardError ? 'border-danger' : ''}`}>
                        <h4 className="ml-n2" style={{ textDecoration: "underline" }}><Translate id="paymentReview.paymentInfo.title" /></h4>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="mr-3">
                                    {isSupportedCardImage(gateway.cardBrand.get()) ?
                                        <img src={getCardBrand(gateway.cardBrand.get())} alt="Card Type" width="40" />
                                        :
                                        <img src={"https://via.placeholder.com/500x250?text=" + gateway.cardBrand.get() ?? ''} alt="Card Type" width="50" />
                                    }
                                </div>
                                <div>
                                    <div className="text-dark font-medium">
                                        **** **** **** ****
                                    </div>
                                    <div className="">
                                        {gateway.formik.values.firstName} {gateway.formik.values.lastName}
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <button type="button" className="reset-btn text-link" onClick={() => setCurrentStep(3)}><i className="mdi mdi-pencil font-18" /></button>
                            </div>
                        </div>
                    </ListGroupItem>

                    <ListGroupItem className="text-left mb-3 p-0">
                        <div className="d-flex align-items-center px-4 py-3 clickable" onClick={() => setCollapseCart(!collapseCart)}>
                            <div className="flex-grow-1">
                                <h4 className="ml-n2" style={{ textDecoration: "underline" }}><Translate id="title.cart.title" /></h4>
                                <div>
                                    <h5 className="text-dark font-medium">
                                        <Translate id="misc.priceValue" values={{ price: (paymentInfo.total_amount / 100).toFixed(2) }} />
                                    </h5>
                                    <div className="">
                                        {(paymentInfo.cart_info?.items && Array.isArray(paymentInfo.cart_info.items)) ? paymentInfo.cart_info.items.length + "x item(s)" : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="pl-3">
                                <i className={`fas ${collapseCart ? 'fa-minus' : 'fa-plus'}`} />
                            </div>
                        </div>

                        <Collapse isOpen={collapseCart}>
                            <div className="px-3 border-top">
                                <ListGroup flush>
                                    <ListGroupItem className="pl-0 pt-0 pr-0">
                                        <div className="text-right pt-2 pr-3">
                                            <div className="d-table ml-auto">
                                                {hasSubtotal &&
                                                    <div className="d-table-row">
                                                        <div className="d-table-cell pl-2"><Translate id="paymentPreviewDetails.title.subTotal" /> :</div>
                                                        <div className="d-table-cell pl-2"><Translate id="misc.priceValue" values={{ price: (paymentInfo.amount_before_tax_fee / 100).toFixed(2) }} /></div>
                                                    </div>
                                                }
                                                {(paymentInfo.tax_info && Array.isArray(paymentInfo.tax_info)) ? paymentInfo.tax_info.map((tax, index) => {
                                                    return (
                                                        <div key={tax.name} className="d-table-row">
                                                            <div className="d-table-cell pl-2">{tax.name}</div>
                                                            <div className="d-table-cell pl-2"><Translate id="misc.priceValue" values={{ price: (tax.amount / 100).toFixed(2) }} /></div>
                                                        </div>
                                                    )
                                                }) : ''}
                                                {(!organisationAbsorbsFee && paymentInfo.spordle_fee && paymentInfo.spordle_fee > 0) &&
                                                    <div className="d-table-row">
                                                        <div className="d-table-cell pl-2"><Translate id="paymentPreviewDetails.title.fee" /> :</div>
                                                        <div className="d-table-cell pl-2"><Translate id="misc.priceValue" values={{ price: (paymentInfo.spordle_fee / 100).toFixed(2) }} /></div>
                                                    </div>
                                                }
                                                <div className="d-table-row text-dark font-bold h3">
                                                    <div className="d-table-cell pl-2 pt-2"><Translate id="paymentPreviewDetails.title.total" /> :</div>
                                                    <div className="d-table-cell pl-2 pt-2"><Translate id="misc.priceValue" values={{ price: (paymentInfo.total_amount / 100).toFixed(2) }} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                </ListGroup>
                            </div>
                        </Collapse>
                    </ListGroupItem>
                </ListGroup>

                <div className="mt-4 d-flex justify-content-center">
                    <Button
                        color="success" className="w-100" size="lg"
                        onClick={() => {
                            checkout()
                        }}
                    >
                        <Translate id="payment.label.sendPayment" />
                    </Button>
                </div>

            </CardBody>
        </Fade>
    );

}

export default PaymentReview;