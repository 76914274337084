import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './layout-components/header/header';
import Footer from './layout-components/footer/footer';
import ThemeRoutes from '../routes/router';
//import Sidebar from './layout-components/sidebar/sidebar';

import { ThemeContext } from '../contexts/ThemeContext';

class Fulllayout extends React.Component{
    constructor(props){
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.state = {
            isOpen: false,
            width: window.innerWidth,
        };
        this.props.history.listen((location, action) => {
            if(window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1){
                document.getElementById('main-wrapper').classList.toggle("show-sidebar");
            }
        });
    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook, Applies when loading or resizing App                           */
    /*--------------------------------------------------------------------------------*/
    componentDidMount(){
        window.addEventListener("load", this.updateDimensions);
        window.addEventListener("resize", this.updateDimensions);
    }

    /*--------------------------------------------------------------------------------*/
    /*Function that handles sidebar, changes when resizing App                        */
    /*--------------------------------------------------------------------------------*/
    updateDimensions(){
        const element = document.getElementById('main-wrapper');
        this.setState({
            width: window.innerWidth,
        });
        const activeSidebarType = 'full'
        switch (activeSidebarType){
            case 'full':
            case 'iconbar':
                if(this.state.width < 1170){
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                    element.classList.add("mini-sidebar");
                }else{
                    element.setAttribute("data-sidebartype", activeSidebarType);
                    element.classList.remove("mini-sidebar");
                }
                break;

            case 'overlay':
                if(this.state.width < 767){
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                }else{
                    element.setAttribute("data-sidebartype", activeSidebarType);
                }
                break;

            default:
        }
    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook                                                                 */
    /*--------------------------------------------------------------------------------*/
    componentWillUnmount(){
        window.removeEventListener("load", this.updateDimensions);
        window.removeEventListener("resize", this.updateDimensions);
    }

    render(){
        /*--------------------------------------------------------------------------------*/
        /* Theme Setting && Layout Options wiil be Change From Here                       */
        /*--------------------------------------------------------------------------------*/
        return (
            <ThemeContext.Consumer>
                {(theme) => {
                    const themeValues = theme.getTheme();
                    return (
                        <div
                            id="main-wrapper"
                            dir={themeValues.activeDir}
                            data-theme={themeValues.activeTheme}
                            data-layout={themeValues.activeThemeLayout}
                            data-sidebartype={themeValues.activeSidebarType}
                            data-sidebar-position={themeValues.activeSidebarPos}
                            data-header-position={themeValues.activeHeaderPos}
                            data-boxed-layout={themeValues.activeLayout}
                        >
                            {/*--------------------------------------------------------------------------------*/}
                            {/* Header                                                                         */}
                            {/*--------------------------------------------------------------------------------*/}
                            <Header />
                            {/*--------------------------------------------------------------------------------*/}
                            {/* Sidebar                                                                        */}
                            {/*--------------------------------------------------------------------------------*/}
                            {/*<Sidebar {...this.props} routes={ThemeRoutes} />*/}
                            {/*--------------------------------------------------------------------------------*/}
                            {/* Page Main-Content                                                              */}
                            {/*--------------------------------------------------------------------------------*/}
                            <div className="page-wrapper d-block">
                                <div className="page-content container-fluid pb-4">
                                    <Switch>
                                        {ThemeRoutes.map((prop, key) => {
                                            if(prop.navlabel){
                                                return null;
                                            }else if(prop.collapse){
                                                return prop.child.map((prop2, key2) => {
                                                    if(prop2.collapse){
                                                        return prop2.subchild.map((prop3, key3) => {
                                                            return (
                                                                <Route path={prop3.path} component={prop3.component} key={key3} />
                                                            );
                                                        });
                                                    }
                                                    return (
                                                        <Route path={prop2.path} component={prop2.component} key={key2} />
                                                    );
                                                });
                                            }else if(prop.redirect){
                                                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                                            }

                                            return (
                                                <Route path={prop.path} component={prop.component} key={key} />
                                            );

                                        })}
                                    </Switch>
                                </div>

                                <Footer />
                            </div>
                        </div>
                    )
                }}
            </ThemeContext.Consumer>
        );
    }
}
export default Fulllayout;
