export default{
    "payment.label.sendPayment" : "Payez",

    // Review
    "paymentReview.action.checkout"     : "Passer la commande",
    "paymentReview.paymentInfo.title"   : "Paiement",
    "paymentReview.paymentInfo.expires" : "Expire",
    "paymentReview.billing.title"       : "Facturation",
    "paymentReview.modify"              : "Changer",

    "paymentReview.resetCart.title"       : "Êtes-vous toujours là?",
    "paymentReview.resetCart.description" : "Nous avons effacé vos informations de paiement pour garantir la sécurité de vos informations. Vous devrez refaire la dernière étape du processus de paiement pour garantir un paiement sécurisé. Merci.",

    // Preview detials
    "paymentPreviewDetails.title.description" : "Description",
    "paymentPreviewDetails.title.price"       : "Prix",
    "paymentPreviewDetails.title.fee"         : "Frais de plateforme",
    "paymentPreviewDetails.title.total"       : "Total",
    "paymentPreviewDetails.title.subTotal"    : "Sous-total",

    // Billing
    "payment.billing.form.title"           : "Informations de facturation",
    "payment.billing.form.label.firstName" : "Prénom",
    "payment.billing.form.label.lastName"  : "Nom de famille",
    "payment.billing.form.label.email"     : "Adresse électronique",
    "payment.billing.form.label.street"    : "Adresse de rue",
    "payment.billing.form.label.city"      : "Ville",
    "payment.billing.form.label.country"   : "Pays",
    "payment.billing.form.label.province"  : "Province",
    "payment.billing.form.label.zip"       : "Zip / code postal",

    "payment.billing.form.validation.firstName.required" : "Prénom requis",
    "payment.billing.form.validation.lastName.required"  : "Nom de famille requis",
    "payment.billing.form.validation.street.required"    : "Adresse requise",
    "payment.billing.form.validation.city.required"      : "Ville requise",
    "payment.billing.form.validation.email.required"     : "Courriel requis",
    "payment.billing.form.validation.email.email"        : "Il doit s'agir d'un format d'adresse courriel valide",
    "payment.billing.form.validation.zip.required"       : "Le code postal est requis",
    "payment.billing.form.validation.zip.match"          : "Le code postal doit être valide",
    "payment.billing.form.validation.country.required"   : "Choisissez un pays",
    "payment.billing.form.validation.province.required"  : "Sélectionnez une province",

    // Card Info
    "payment.cardInfo.form.title"                 : "Information de paiement",
    "payment.cardInfo.form.label.name"            : "Nom sur la carte",
    "payment.cardInfo.form.label.cardNumber"      : "Numéro de carte",
    "payment.cardInfo.form.helper.cardNumber"     : "Cartes acceptées : ",
    "payment.cardInfo.form.label.expirationDate"  : "Date d'expiration",
    "payment.cardInfo.form.helper.expirationDate" : "MM/AA",
    "payment.cardInfo.form.label.cvv"             : "CVV",
    "payment.cardInfo.form.helper.cvv"            : "Trouver le CVV",
    "payment.cardInfo.form.helper.findCvv"        : "Le CVV se retrouve habituellement en arrière de la carte et est de 3 à 4 chiffres.",
    "payment.cardInfo.form.label.terms.1"         : "Je comprend et j'accepte les ",
    "payment.cardInfo.form.label.terms.2"         : "termes et conditions",
    "payment.cardInfo.form.label.terms.3"         : " ainsi que les ",
    "payment.cardInfo.form.label.terms.4"         : "politiques de remboursement",

    "payment.cardInfo.form.validation.name.required"               : "Le nom sur la carte est obligatoire",
    "payment.cardInfo.form.validation.cardNumber.required"         : "Veuillez saisir votre numéro de carte",
    "payment.cardInfo.form.validation.cardNumber.match"            : "Il doit s'agir d'un numéro de carte valide",
    "payment.cardInfo.form.validation.expirationDate.required"     : "La date d'expiration est obligatoire",
    "payment.cardInfo.form.validation.expirationDate.match"        : "La date d'expiration doit être un format de date valide",
    "payment.cardInfo.form.validation.expirationDate.invalidMonth" : "Le mois doit être valide. Le nombre maximum de mois est 12 (décembre)",
    "payment.cardInfo.form.validation.cvv.required"                : "CVV est requis",
    "payment.cardInfo.form.validation.cvv.match"                   : "CVV doit être composé de 3 chiffres",
    "payment.cardInfo.form.validation.terms.required"              : "Veuillez lire et accepter les termes et conditions ainsi que les politiques de remboursement pour procéder avec votre paiement.",

    // Done
    "paymentDone.proccess.title"       : "Le paiement est en cours...",
    "paymentDone.proccess.description" : "Veuillez attendre pendant que nous procédons au paiement.",

    "paymentDone.success.title"        : "Paiement complet!",
    "paymentDone.success.description"  : "Merci pour votre achat. Les détails de la transaction seront envoyés à",
    "paymentDone.success.autoRedirect" : "Vous serez automatiquement redirigé dans quelques secondes...",
    "paymentDone.success.billing"      : "Numéro de la facture",
    "paymentDone.success.action.SID"   : "Retour vers Spordle",
    "paymentDone.success.action.ENR"   : "Retour vers Eventn'Roll",

    "paymentDone.error.title"                 : "Erreur lors du paiement",
    "paymentDone.error.description"           : "Une erreur est survenu lors du paiement.",
    "paymentDone.error.action.contactSpordle" : "Contacter Spordle",
    "paymentDone.error.action.seeAgain"       : "Revoir mes données de facturation",

    // Error
    "payment.error.title"   : "Un problème est survenu lors du traitement de votre transaction.",
    "payment.error.server"  : "Veuillez actualiser la page et réessayer.",
    "payment.error.unknown" : "Il manque des informations dans la préparation de cette transaction.",

    // Gateway processing errors
    "payment.gateway.error.6002" : "La transaction à laquelle vous essayer d'accéder à déjà été complétée. Merci.",
    "payment.gateway.error.6003" : "La transaction à laquelle vous essayez d'accéder n'a pas pu être trouvée.",
    "payment.gateway.error.6004" : "La transaction à laquelle vous essayer d'accéder à été annulée.",
    "payment.gateway.error.6005" : "La transaction à laquelle vous essayer d'accéder est expirée.",

    // Paysafe processing errors
    "payment.paysafe.processing.error.generic" : "L'erreur semble être dû à la configuration de cette transaction.",
    "payment.paysafe.processing.error.3002"    : "Vous avez soumis un numéro de carte ou une marque non valide ou une combinaison de numéro de carte et de marque avec votre demande. ",
    "payment.paysafe.processing.error.3004"    : "Le code postal / postal doit être fourni pour une demande de chèque AVS. ",
    "payment.paysafe.processing.error.3005"    : "Vous avez soumis une valeur CVV incorrecte avec votre demande. ",
    "payment.paysafe.processing.error.3006"    : "Vous avez soumis un numéro de carte de crédit expiré avec votre demande. ",
    "payment.paysafe.processing.error.3007"    : "Votre demande a échoué à la vérification AVS. Notez que le montant a toujours été réservé sur la carte du client et sera libéré dans 3 à 5 jours ouvrables. Veuillez vous assurer que l'adresse de facturation est exacte avant de réessayer.",
    "payment.paysafe.processing.error.3008"    : "Vous avez soumis un type de carte pour lequel le compte marchand n'est pas configuré. ",
    "payment.paysafe.processing.error.3009"    : "Votre demande a été refusée par la banque émettrice. ",
    "payment.paysafe.processing.error.3011"    : "Votre demande a été refusée par la banque émettrice car la carte utilisée est une carte restreinte. Contactez la société de carte de crédit du titulaire de la carte pour une enquête plus approfondie. ",
    "payment.paysafe.processing.error.3012"    : "Votre demande a été refusée par la banque émettrice car la date d'expiration de la carte de crédit soumise n'est pas valide. ",
    "payment.paysafe.processing.error.3013"    : "Votre demande a été refusée par la banque émettrice en raison de problèmes avec le compte de carte de crédit. ",
    "payment.paysafe.processing.error.3014"    : "Votre demande a été refusée - la banque émettrice a renvoyé une réponse inconnue. Contactez la société de carte de crédit du titulaire de la carte pour une enquête plus approfondie. ",
    "payment.paysafe.processing.error.3015"    : "La banque vous a demandé de traiter la transaction manuellement en appelant la société de carte de crédit du titulaire de la carte. ",
    "payment.paysafe.processing.error.3016"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - il peut s'agir d'une carte perdue ou volée. ",
    "payment.paysafe.processing.error.3017"    : "Vous avez soumis un numéro de carte de crédit non valide avec votre demande. ",
    "payment.paysafe.processing.error.3018"    : "La banque vous a demandé de réessayer la transaction. ",
    "payment.paysafe.processing.error.3019"    : "Votre demande a échoué à la vérification du CVV. Veuillez noter que le montant peut encore avoir été réservé sur la carte du client, auquel cas il sera débloqué dans 3 à 5 jours ouvrables. ",
    "payment.paysafe.processing.error.3020"    : "La banque vous a demandé de réessayer la transaction. ",
    "payment.paysafe.processing.error.3021"    : "Le numéro de confirmation inclus dans cette demande est introuvable. ",
    "payment.paysafe.processing.error.3022"    : "La carte a été refusée en raison de fonds insuffisants. ",
    "payment.paysafe.processing.error.3023"    : "Votre demande a été refusée par la banque émettrice en raison de ses règles propriétaires relatives à l'activité des cartes. ",
    "payment.paysafe.processing.error.3024"    : "Votre demande a été refusée, car la banque émettrice n'autorise pas la transaction pour cette carte. ",
    "payment.paysafe.processing.error.3025"    : "La passerelle de traitement externe a signalé des données non valides. ",
    "payment.paysafe.processing.error.3026"    : "La passerelle de traitement externe a signalé que le type de compte n'est pas valide. ",
    "payment.paysafe.processing.error.3027"    : "La passerelle de traitement externe a signalé qu'une limite a été dépassée. ",
    "payment.paysafe.processing.error.3028"    : "La passerelle de traitement externe a signalé une erreur système. ",
    "payment.paysafe.processing.error.3029"    : "La passerelle de traitement externe a rejeté la transaction. ",
    "payment.paysafe.processing.error.3030"    : "La passerelle de traitement externe a signalé que la transaction n’était pas autorisée. ",
    "payment.paysafe.processing.error.3031"    : "Le numéro de confirmation que vous avez soumis avec votre demande fait référence à une transaction qui n'est pas en attente. ",
    "payment.paysafe.processing.error.3032"    : "Votre demande a été refusée par la banque émettrice ou la passerelle externe car la carte se trouve probablement dans l'une de leurs bases de données négatives. ",
    "payment.paysafe.processing.error.3035"    : "Votre demande a été refusée en raison du dépassement du nombre de tentatives de code PIN. ",
    "payment.paysafe.processing.error.3036"    : "Votre demande a été refusée en raison d'un émetteur non valide. ",
    "payment.paysafe.processing.error.3037"    : "Votre demande a été refusée, car elle n'est pas valide. ",
    "payment.paysafe.processing.error.3038"    : "Votre demande a été refusée en raison de l'annulation du client. ",
    "payment.paysafe.processing.error.3039"    : "Votre demande a été refusée en raison d'une valeur d'authentification non valide. ",
    "payment.paysafe.processing.error.3040"    : "Votre demande a été refusée, car le type de demande n'est pas autorisé sur la carte. ",
    "payment.paysafe.processing.error.3041"    : "Votre demande a été refusée en raison d'un délai d'expiration. ",
    "payment.paysafe.processing.error.3042"    : "Votre demande a été refusée en raison d'une erreur cryptographique. ",
    "payment.paysafe.processing.error.3044"    : "Vous avez envoyé une demande en double. ",
    "payment.paysafe.processing.error.3045"    : "Vous avez soumis un format de date non valide pour cette demande. ",
    "payment.paysafe.processing.error.3046"    : "La transaction a été refusée car le montant a été défini sur zéro. ",
    "payment.paysafe.processing.error.3047"    : "La transaction a été refusée car le montant dépasse la limite plancher. ",
    "payment.paysafe.processing.error.3048"    : "La transaction a été refusée car le montant est inférieur à la limite plancher. ",
    "payment.paysafe.processing.error.3049"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - la carte de crédit est expirée. ",
    "payment.paysafe.processing.error.3050"    : "La banque vous a demandé de récupérer la carte auprès du titulaire de la carte - une activité frauduleuse est suspectée. ",
    "payment.paysafe.processing.error.3051"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - contactez l'acquéreur pour plus d'informations. ",
    "payment.paysafe.processing.error.3052"    : "La banque a demandé que vous récupériez la carte auprès du titulaire de la carte - la carte de crédit est limitée. ",
    "payment.paysafe.processing.error.3053"    : "La banque vous a demandé de récupérer la carte auprès du titulaire de la carte - veuillez appeler l'acquéreur. ",
    "payment.paysafe.processing.error.3054"    : "La transaction a été refusée en raison d'une fraude présumée. ",
    "payment.paysafe.processing.error.3055"    : "Ce type de transaction n'est pas pris en charge. ",
    "payment.paysafe.processing.error.3056"    : "Le type de transaction d'origine ne correspond pas. ",
    "payment.paysafe.processing.error.3057"    : "Veuillez indiquer au titulaire de la carte d'appeler l'émetteur - ne réessayez pas la transaction.",

    // Paysafe tokenize errors
    "payment.paysafe.tokenize.error.9001" : "Aucune connexion au serveur, veuillez réessayer. Si le problème persiste, veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9002" : "Erreur de communication avec le serveur.",
    "payment.paysafe.tokenize.error.9003" : "Il y a eu des erreurs avec les informations de paiement. Veuillez revérifier les champs erronés.",
    "payment.paysafe.tokenize.error.9004" : "Une erreur s'est produite (9004), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9015" : "Une erreur s'est produite (9015), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9034" : "Une erreur s'est produite (9034), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9035" : "Une erreur s'est produite (9035), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9036" : "Une erreur s'est produite (9036), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9037" : "Erreur de communication avec le serveur 3DS.",
    "payment.paysafe.tokenize.error.9039" : "L'inscription n'est pas acceptée. Réessayez avec la même carte ou une autre.",
    "payment.paysafe.tokenize.error.9040" : "Authentification non acceptée. Réessayez avec la même carte ou une autre.",
    "payment.paysafe.tokenize.error.9041" : "Une erreur s'est produite (9041), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9042" : "L’utilisateur a abandonné l’authentification.",
    "payment.paysafe.tokenize.error.9043" : "Une erreur s'est produite (9043), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9044" : "Une erreur s'est produite (9044), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9045" : "Une erreur s'est produite (9045), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9046" : "Une erreur s'est produite (9046), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9047" : "Une erreur s'est produite (9047), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9048" : "Une erreur s'est produite (9048), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9049" : "Une erreur s'est produite (9049), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9050" : "Une erreur s'est produite (9050), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9051" : "Une erreur s'est produite (9051), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9052" : "Une erreur s'est produite (9052), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9110" : "Une erreur s'est produite (9110), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9111" : "Une erreur s'est produite (9111), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9112" : "Une erreur s'est produite (9112), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9113" : "Une erreur s'est produite (9113), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9114" : "Une erreur s'est produite (9114), veuillez contacter notre support.",
    "payment.paysafe.tokenize.error.9125" : "Une erreur s'est produite (9125), veuillez contacter notre support.",
}