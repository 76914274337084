export default{
    "footer.copyright" : "Propulsée par <a>Spordle</a> ©{date} Spordle Inc. Tous droits réservés.",
    "footer.terms"     : "Termes et conditions",
    "footer.terms.a"   : "fr/termes",
    "footer.consent"   : "Consentement",
    "footer.privacy"   : "Politique de confidentialité",
    "footer.privacy.a" : "fr/confidentialite",
    "footer.ads"       : "Publicité ciblée",
    "footer.about"     : "À propos",
    "footer.about.a"   : "fr/a-propos",
    "footer.solutions" : "Solutions",
};