import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useIntl } from 'react-intl';
import styles from "./Footer.module.scss";
import { I18nContext } from '../../../contexts/I18nContext';
import { useContext } from 'react';

const Footer = ({ className }) => {
    const { formatMessage } = useIntl();
    // const { locale } = useRouter();
    const { getGenericLocale } = useContext(I18nContext);
    const locale = getGenericLocale();

    const getHref = (endpath) => `https://erp.spordle.com/${endpath || ""}`;

    return (
        <footer className={stringBuilder(className, "p-3 bg-white border-top font-16")}>
            <div className="container d-flex align-items-center gap-3 flex-wrap" style={{ gap: "1.6rem" }}>
                <nav className="mr-auto">
                    <ul className="list-inline mb-0 small d-flex flex-wrap gap-3">
                        <li className="list-inline-item">
                            <a className="text-muted" href={getHref(formatMessage({ id: "footer.terms.a" }))} target="_blank" rel="noopener noreferrer">
                                <Translate id="footer.terms" />
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a className="text-muted" href={getHref(formatMessage({ id: "footer.privacy.a" }))} target="_blank" rel="noopener noreferrer">
                                <Translate id="footer.privacy" />
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <button className={stringBuilder(styles.btn, "text-muted reset-btn")} type="button" onClick={() => window.Didomi?.preferences.show()}>
                                <Translate id="footer.consent" />
                            </button>
                        </li>
                        <li className="list-inline-item">
                            <a className="text-muted" href={getHref(formatMessage({ id: "footer.about.a" }))} target="_blank" rel="noopener noreferrer">
                                <Translate id="footer.about" />
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a className="text-muted" href={getHref(locale == "fr" ? "fr/" : "")} target="_blank" rel="noopener noreferrer">
                                <Translate id="footer.solutions" />
                            </a>
                        </li>
                    </ul>
                </nav>
                <small className="text-muted">
                    <Translate
                        id='footer.copyright'
                        values={{
                            date: new Date().getFullYear(),
                            a: (chunks) => <a href={getHref(locale == "fr" ? "fr/" : "")} target="_blank" rel="noopener noreferrer">{chunks}</a>,
                        }}
                    />
                </small>
            </div>
        </footer>
    );
};

export default Footer;
