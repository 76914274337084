import PaymentAPI from "../APIGatewayPayment";
import { serverError } from "../CancellableAPI";

export function preparePayment(paymentToken){
    return PaymentAPI.get(`payment/preparePayment?token=${paymentToken}`)
        .then((response) => {
            if(response.data.payment_info){
                return response.data.payment_info;
            }
            throw Error(response.data.error[0].code) // status error

        }, serverError);
}