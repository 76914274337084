import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './app';
import './assets/scss/style.scss';
import '@spordle/helpers';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import { getI18nPolyfills } from '@spordle/intl-elements';
import { setupCustomValidation } from '@spordle/formik-elements';

Promise.all([
    getI18nPolyfills(),
])
    .catch(console.error)
    .finally(() => {
        // Load app only once google-maps is done loading(fail or success)
        // Is equivalent to have a script tag with async and defer like we did before
        ReactDOM.render(
            <App />, document.getElementById('root'),
        );
    });

setupCustomValidation();