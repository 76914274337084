import React from 'react';

import {
	Nav,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';

// Language
import { LOCALES } from '../../../i18n';
import Translate from '@spordle/intl-elements';

// Context Providers
import { ThemeContext } from '../../../contexts/ThemeContext'
import { I18nContext } from '../../../contexts/I18nContext';
import { AuthContext } from '../../../contexts/AuthContext';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import images from "@spordle/ui-kit";
const noProfileLogo = images.noprofile;

class Header extends React.Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.searchtoggle = this.searchtoggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);
		this.state = {
			isOpen: false,
			collapse: false
		};
	}

	goBackToReferrer = () => {
        if(this.context.userReferrer){
			window.location.href = this.context.userReferrer;
        }
    }

	/*--------------------------------------------------------------------------------*/
	/*Day Night Mode Switch                                                           */
	/*--------------------------------------------------------------------------------*/
	switchDayNight = (e, theme) => {
		if(!e.state.value){
			theme.setTheme('dark');
		}else{
			theme.setTheme('light');
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	searchtoggle(){
		this.setState({
			collapse: !this.state.collapse
		})
	}
	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		let activeSidebarType = "full";
		switch (activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						activeSidebarType
					);
				}
				break;
			default:
		}
	};

	render() {


		return (
			<ThemeContext.Consumer>
				{(theme) => {
					const themeValues = theme.getTheme();
					return (
						<I18nContext.Consumer>
							{(i18n) =>
								<header className="topbar navbarbg" data-navbarbg={themeValues.activeNavbarBg}>
									<Navbar className={"top-navbar " + (themeValues.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
										<div className="navbar-header" id="logobg" data-logobg={themeValues.activeLogoBg}>
											{/*--------------------------------------------------------------------------------*/}
											{/* Mobile View Toggler  [visible only after 768px screen]                         */}
											{/*--------------------------------------------------------------------------------
											<span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
												<i className="ti-menu ti-close" />
											</span>*/}
											{/*--------------------------------------------------------------------------------*/}
											{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
											{/*--------------------------------------------------------------------------------*/}
											<NavbarBrand href="/">
												<span className="logo-text">
													{/*<img src={logodarktext} className="dark-logo" alt="homepage" />
													<img src={logolighttext} className="light-logo" alt="homepage" />*/}
													<span className="dark-logo text-dark ml-2">Spordle Gateway</span>
													<span className="light-logo text-white ml-2">Spordle Gateway</span>
												</span>
											</NavbarBrand>
											{/*--------------------------------------------------------------------------------*/}
											{/* Mobile View Toggler  [visible only after 768px screen]                         */}
											{/*--------------------------------------------------------------------------------*/}
											<span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
												<i className="ti-more" />
											</span>
										</div>
										<Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={themeValues.activeNavbarBg} >
											<Nav className="ml-auto float-right" navbar>

												{/*--------------------------------------------------------------------------------*/}
												{/* Start Create New Dropdown                                                      */}
												{/*--------------------------------------------------------------------------------*/}
												<UncontrolledDropdown nav inNavbar>
													<DropdownToggle nav>
														<i className="mdi mdi-earth" style={{fontSize: "28px", verticalAlign: "middle"}}></i>
													</DropdownToggle>
													<DropdownMenu right>

														<DropdownItem onClick={() => { i18n.setLocale(LOCALES.FRENCH)} }>Français</DropdownItem>
														<DropdownItem onClick={() => { i18n.setLocale(LOCALES.ENGLISH)} }>English</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
												{/*--------------------------------------------------------------------------------*/}
												{/* End Create New Dropdown                                                        */}
												{/*--------------------------------------------------------------------------------*/}

												{/*--------------------------------------------------------------------------------*/}
												{/* Start Day / Night Mode Switch                                                  */}
												{/*--------------------------------------------------------------------------------*/}
												{/*<NavItem>
													<NavLink>
														<Switch
															onText={<i className="far fa-sun" />}
															offText={<i className="far fa-moon" />}
															defaultValue={themeValues.activeTheme === "light"}
															onChange={this.switchDayNight}
														/>
													</NavLink>
												</NavItem>*/}
												{/*--------------------------------------------------------------------------------*/}
												{/* END Day / Night Mode Switch                                                  */}
												{/*--------------------------------------------------------------------------------*/}

												{/*--------------------------------------------------------------------------------*/}
												{/* Start Profile Dropdown                                                         */}
												{/*--------------------------------------------------------------------------------*/}
												{(this.context.user && this.context.user !== null) ?
													<UncontrolledDropdown nav inNavbar>
														<DropdownToggle nav caret className="pro-pic">
															<img
																src={(this.context.user.logo) ? this.context.user.logo : noProfileLogo}
																alt="user"
																className="rounded-circle"
																width="32" height="32"
															/>
														</DropdownToggle>
														<DropdownMenu right className="user-dd">
															<div className="d-flex no-block align-items-center p-3 bg-info text-white mb-2">
																<div className="">
																	<img
																		src={(this.context.user.logo) ? this.context.user.logo : noProfileLogo}
																		alt="user"
																		className="rounded-circle bg-white"
																		width="60" height="60"
																	/>
																</div>
																<div className="ml-2">
																	<h4 className="mb-0 text-white">{this.context.user.firstName+' '+this.context.user.lastName}</h4>
																	<p className=" mb-0">{this.context.user.email}</p>
																</div>
															</div>
															<DropdownItem onClick={this.goBackToReferrer}>
																<i className="mdi mdi-logout mr-1 ml-1" /> <Translate id='return_to_referer' values={{productName: this.context.product.productName}} />
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												: null}
												{/*--------------------------------------------------------------------------------*/}
												{/* End Profile Dropdown                                                           */}
												{/*--------------------------------------------------------------------------------*/}
											</Nav>
										</Collapse>
									</Navbar>
								</header>
							}
						</I18nContext.Consumer>
					)
				}}
			</ThemeContext.Consumer>

		);
	}
}
export default Header;
