/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Router, Switch } from "react-router-dom";

import { I18nProvider } from './i18n';
import ThemeContextProvider from './contexts/ThemeContext';
import I18nContextProvider, { I18nContext } from './contexts/I18nContext';
import AuthContextProvider from './contexts/AuthContext';

import PrivateRoute from './routes/PrivateRoutes';
import PublicRoute from './routes/PublicRoutes';
import indexRoutes from './routes/';
import authRoutes from './routes/authRoutes';
import { history } from './service/history';

import AccountCreationProvider from './contexts/AccountCreationContext';
import PaymentContextProvider from './contexts/PaymentContext';
import { FormikElementsGlobalConfigs } from '@spordle/formik-elements';

class App extends React.Component{
    render(){
        return (
            <I18nContextProvider>
                <ThemeContextProvider>
                    <AuthContextProvider>
                        <AccountCreationProvider>
                            <PaymentContextProvider>
                                <I18nContext.Consumer>
                                    {({ locale }) => (
                                        <FormikElementsGlobalConfigs
                                            formikPhoneInput={{
                                                suggestedCountries: [ 'ca', 'us' ],
                                                defaultSelectedCountry: 'ca',
                                            }}
                                            formikDateTime={{
                                                dateFormat: 'YYYY-MM-DD',
                                            }}
                                        >
                                            <I18nProvider locale={locale}>
                                                <Router basename="/" history={history}>
                                                    <Switch>
                                                        {authRoutes.map((prop, key) => <PublicRoute path={prop.path} key={key} component={prop.component} />)}
                                                        {indexRoutes.map((prop, key) => <PrivateRoute path={prop.path} key={key} component={prop.component} />)}
                                                    </Switch>
                                                </Router>
                                            </I18nProvider>
                                        </FormikElementsGlobalConfigs>
                                    )}
                                </I18nContext.Consumer>
                            </PaymentContextProvider>
                        </AccountCreationProvider>
                    </AuthContextProvider>
                </ThemeContextProvider>
            </I18nContextProvider>
        )
    }
}

export default App;