import React, { createContext, PureComponent } from 'react';
import APIGatewayAccount from '../api/APIGatewayAccount';
import { serverError } from '../api/CancellableAPI';

export const AccountCreation = createContext();

class AccountCreationProvider extends PureComponent {
    validateAccessToken = (lang, ref_id, tok, product) => {
        return APIGatewayAccount.get(`validateAccessToken?product=${product}&tok=${tok}&ref_id=${ref_id}&lang=${lang}`)
            .then(response => response, serverError)
    }

    getTermsAndConditions = (product, refId) => {
        return APIGatewayAccount.get(`lookupTerms?product=${product}&ref_id=${refId}`)
            .then((response) => {
                if(!response.data.status)
                    throw new Error('Cannot get the terms and conditions');
                return response.data;
            }, serverError)
    }

    step1 = (values, props) => {
        let createMerchantForm = new FormData();
        createMerchantForm.append('product', props.product);
        createMerchantForm.append('ref_id', props.ref_id);
        createMerchantForm.append('merchant_name', values.merchant_name);
        createMerchantForm.append('locale', values.locale);

        let createMerchantAccountForm = new FormData();
        createMerchantAccountForm.append('product', props.product);
        createMerchantAccountForm.append('ref_id', props.ref_id);
        createMerchantAccountForm.append('merchant_name', values.merchant_name);
        createMerchantAccountForm.append('locale', values.locale);
        createMerchantAccountForm.append('legal_entity', values.legal_entity);
        createMerchantAccountForm.append('category', values.category);
        createMerchantAccountForm.append('url', values.url);
        createMerchantAccountForm.append('phone_customer_support', values.phone_customer_support);
        createMerchantAccountForm.append('email', values.email);
        createMerchantAccountForm.append('dynamic_descriptor', values.dynamic_descriptor);
        createMerchantAccountForm.append('phone', values.phone);
        createMerchantAccountForm.append('type', values.type);
        createMerchantAccountForm.append('description', values.description);
        createMerchantAccountForm.append('federal_tax_number', values.federal_tax_number);
        createMerchantAccountForm.append('yearly_volume_range', values.yearly_volume_range);
        createMerchantAccountForm.append('has_previously_processed_cards', values.has_previously_processed_cards);
        createMerchantAccountForm.append('ships_goods', values.ships_goods);
        createMerchantAccountForm.append('refund_policy', values.refund_policy);
        createMerchantAccountForm.append('street', values.street);
        createMerchantAccountForm.append('street2', values.street2);
        createMerchantAccountForm.append('city', values.city);
        createMerchantAccountForm.append('zip', values.zip);
        createMerchantAccountForm.append('country', values.country);
        createMerchantAccountForm.append('province', values.province);
        createMerchantAccountForm.append('refund_policy_description', values.refund_policy_description);
        createMerchantAccountForm.append('delivery_time_range', values.delivery_time_range);
        createMerchantAccountForm.append('additional_payment_methods', values.additional_payment_methods);

        return APIGatewayAccount.post('createMerchant', createMerchantForm)
            .then(async response => {
                if(!response.data.status)
                    if(response.data.returned.response.error.code === '8502')
                        throw new Error('8502');
                    else
                        throw new Error('8500');

                await APIGatewayAccount.post('createMerchantAccount', createMerchantAccountForm)
                    .then(response => {
                        if(!response.data.status)
                            if(response.data.returned.response.error.code === '5068'){
                                return Promise.reject(response.data.returned.response.error.fieldErrors);
                            } else {
                                throw new Error('Cannot create merchant account');
                            }
                    })
            }, serverError);
    }

    step2 = (values, props) => {
        console.log("step2")
        console.log(values)
        console.log(props)
        let step2Form = new FormData();
        step2Form.append('tok', props.tok);
        step2Form.append('ref_id', props.ref_id);
        step2Form.append('product', props.product);
        step2Form.append('productName', props.productName);
        step2Form.append('lang', props.lang);
        step2Form.append('first_name', values.first_name);
        step2Form.append('last_name', values.last_name);
        step2Form.append('email_owner', values.email_owner);
        step2Form.append('job_title', values.job_title);
        step2Form.append('phone_owner', values.phone_owner);
        step2Form.append('date_of_birth', values.date_of_birth);
        step2Form.append('percentage_ownership', values.percentage_ownership);
        step2Form.append('street_owner', values.street_owner);
        step2Form.append('street2_owner', values.street2_owner);
        step2Form.append('city_owner', values.city_owner);
        step2Form.append('zip_owner', values.zip_owner);
        step2Form.append('country_owner', values.country_owner);
        step2Form.append('province_owner', values.province_owner);
        step2Form.append('years_at_address', values.years_at_address);
        step2Form.append('prev_street_owner', values.prev_street_owner);
        step2Form.append('prev_street2_owner', values.prev_street2_owner);
        step2Form.append('prev_city_owner', values.prev_city_owner);
        step2Form.append('prev_zip_owner', values.prev_zip_owner);
        step2Form.append('prev_country_owner', values.country_owner);
        step2Form.append('prev_province_owner', values.province_owner);
        step2Form.append('prev_years_at_address', values.years_at_address);

        return APIGatewayAccount.post("createBusinessOwner", step2Form)
            .then((response) => {
                if(!response.data.status){
                    switch (response.data.returned.response.error.code) {
                        case '5068':
                            return Promise.reject(response.data.returned.response.error.fieldErrors);
                        default:
                            throw new Error('general');
                    }
                }
                return true;
            }, serverError)
    }

    step3 = (values, props) => {
        let step3Form = new FormData();
        step3Form.append('tok', props.tok);
        step3Form.append('ref_id', props.ref_id);
        step3Form.append('product', props.product);
        step3Form.append('productName', props.productName);
        step3Form.append('lang', props.lang);
        step3Form.append('account_number', values.account_number);
        step3Form.append('transit_number', values.transit_number);
        step3Form.append('institution_id', values.institution_id);

        return APIGatewayAccount.post("createMerchantBankAccount", step3Form)
            .then((response) => {
                if(!response.data.status){
                    if(response.data.returned.response.error.code === '5068'){
                        return Promise.reject(response.data.returned.response.error.fieldErrors);
                    } else {
                        throw new Error('Cannot create merchant account');
                    }
                }
            }, serverError)
    }

    step4 = (version, product, refId) => {
        let step4Form = new FormData();
        step4Form.append('product', product);
        step4Form.append('ref_id', refId);
        step4Form.append('terms_and_condition', version);

        return APIGatewayAccount.post('acceptTermsAndConditions', step4Form)
            .then(response => {
                if(!response.data.status)
                    throw new Error('Cannot accept terms and conditions');
            }, serverError)
    }

    step5 = (product, refId, formValues) => {
        let step5Form = new FormData();
        step5Form.append('product', product);
        step5Form.append('ref_id', refId);
        step5Form.append('user_name', formValues.user_name);
        step5Form.append('password', formValues.password);
        step5Form.append('account_email', formValues.account_email);
        step5Form.append('recovery_question', formValues.recovery_question);
        step5Form.append('answer', formValues.answer);

        return APIGatewayAccount.post('createBankUser', step5Form)
            .then(response => {
                if(!response.data.status){
                    if(response.data.returned.response.error.code === '5068'){
                        return Promise.reject(response.data.returned.response.error.fieldErrors);
                    }
                    else
                        throw new Error('Cannot create bank user');
                }
            }, serverError)
    }

    activateAccount = (product, refId) => {
        let activationStepForm = new FormData();
        activationStepForm.append('product', product);
        activationStepForm.append('ref_id', refId);

        return APIGatewayAccount.post('activateAccount', activationStepForm)
            .then(response => {
                if(!response.data.status)
                    throw new Error('Cannot activate account');
            }, serverError)
    }

    render() {
        return (
            <AccountCreation.Provider value={{
                validateAccessToken: this.validateAccessToken,
                getTermsAndConditions: this.getTermsAndConditions,
                step1: this.step1,
                step2: this.step2,
                step3: this.step3,
                step4: this.step4,
                step5: this.step5,
                activateAccount: this.activateAccount
            }}>
                {this.props.children}
            </AccountCreation.Provider>
        );
    }
}

export default AccountCreationProvider;