import Axios from "axios";

export const serverError = error => {
    if(Axios.isCancel(error)){
        throw new Error('Cancelled');
    }else
        throw new Error("server");
}

export let activeCalls = [];

export const AxiosCancelAll = () => {
    activeCalls.forEach(call => call());
    activeCalls = [];
}

export const AxiosIsCancelled = message => message === 'Cancelled';