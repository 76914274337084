export default{
    "payment.label.sendPayment" : "Pay Now",

    // Review
    "paymentReview.action.checkout"     : "Checkout",
    "paymentReview.paymentInfo.title"   : "Payment",
    "paymentReview.paymentInfo.expires" : "Expires",
    "paymentReview.billing.title"       : "Billing",
    "paymentReview.modify"              : "Modify",

    "paymentReview.resetCart.title"       : "Are you still here?",
    "paymentReview.resetCart.description" : "We have cleared your payment information to ensure your information stays safe. You will have to redo the last step of the payment process to ensure a secure payment. Thank you.",


    // Preview Details
    "paymentPreviewDetails.title.description" : "Description",
    "paymentPreviewDetails.title.fee"         : "Platform fee",
    "paymentPreviewDetails.title.price"       : "Price",
    "paymentPreviewDetails.title.total"       : "Total",
    "paymentPreviewDetails.title.subTotal"    : "Subtotal",

    // Billing
    "payment.billing.form.title"           : "Billing info",
    "payment.billing.form.label.firstName" : "First Name",
    "payment.billing.form.label.lastName"  : "Last Name",
    "payment.billing.form.label.email"     : "Email Address",
    "payment.billing.form.label.street"    : "Street Address",
    "payment.billing.form.label.city"      : "City",
    "payment.billing.form.label.country"   : "Country",
    "payment.billing.form.label.province"  : "Province",
    "payment.billing.form.label.zip"       : "ZIP / Postal code",

    "payment.billing.form.validation.firstName.required" : "First name required",
    "payment.billing.form.validation.lastName.required"  : "Last name required",
    "payment.billing.form.validation.street.required"    : "Street address required",
    "payment.billing.form.validation.city.required"      : "City required",
    "payment.billing.form.validation.email.required"     : "Email required",
    "payment.billing.form.validation.email.email"        : "This must be a valid email address format",
    "payment.billing.form.validation.zip.required"       : "Zip code is required",
    "payment.billing.form.validation.zip.match"          : "Postal code must be valid",
    "payment.billing.form.validation.country.required"   : "Select a country",
    "payment.billing.form.validation.province.required"  : "Select a province",

    // Card Info
    "payment.cardInfo.form.title"                 : "Payment info",
    "payment.cardInfo.form.label.name"            : "Name on card",
    "payment.cardInfo.form.label.cardNumber"      : "Card Number",
    "payment.cardInfo.form.helper.cardNumber"     : "Accepted Cards: ",
    "payment.cardInfo.form.label.expirationDate"  : "Expiration Date",
    "payment.cardInfo.form.helper.expirationDate" : "MM/YY",
    "payment.cardInfo.form.label.cvv"             : "CVV",
    "payment.cardInfo.form.helper.cvv"            : "Find the CVV",
    "payment.cardInfo.form.helper.findCvv"        : "The CVV is usually found on the back of the card and is 3 to 4 digits long.",
    "payment.cardInfo.form.label.terms.1"         : "I understand and accept the ",
    "payment.cardInfo.form.label.terms.2"         : "terms and conditions",
    "payment.cardInfo.form.label.terms.3"         : " as well as ",
    "payment.cardInfo.form.label.terms.4"         : "refund policies",

    "payment.cardInfo.form.validation.name.required"               : "Name on card is required",
    "payment.cardInfo.form.validation.cardNumber.required"         : "Please enter your card number",
    "payment.cardInfo.form.validation.cardNumber.match"            : "It must be a valid card number",
    "payment.cardInfo.form.validation.expirationDate.required"     : "Expiration date is required",
    "payment.cardInfo.form.validation.expirationDate.match"        : "Expiration date must be a valid date format",
    "payment.cardInfo.form.validation.expirationDate.invalidMonth" : "Month must be valid. The maximum month number is 12 (december)",
    "payment.cardInfo.form.validation.cvv.required"                : "CVV is required",
    "payment.cardInfo.form.validation.cvv.match"                   : "CVV must be 3 numbers",
    "payment.cardInfo.form.validation.terms.required"              : "Please read and accept the terms and conditions as well as refund policies to proceed with your payment.",

    // Done
    "paymentDone.proccess.title"       : "Payment is in progress...",
    "paymentDone.proccess.description" : "Please wait while we process the payment.",

    "paymentDone.success.title"        : "Payment Complete!",
    "paymentDone.success.description"  : "Thank you for your purchase. The details of the transaction will be sent sent to",
    "paymentDone.success.autoRedirect" : "You will be redirected automatically in a few secondes...",
    "paymentDone.success.billing"      : "Transaction",
    "paymentDone.success.action.SID"   : "Back to the Spordle",
    "paymentDone.success.action.ENR"   : "Back to Eventn'Roll",

    "paymentDone.error.title"                 : "Error during payment",
    "paymentDone.error.description"           : "An error occurred during payment.",
    "paymentDone.error.action.contactSpordle" : "Contact Spordle",
    "paymentDone.error.action.seeAgain"       : "Review my billing data",


    // Error
    "payment.error.title"   : "There was a problem while we processed your transaction.",
    "payment.error.server"  : "Please refresh the page and try again.",
    "payment.error.unknown" : "Information is missing for this transaction.",

    // Gateway processing errors
    "payment.gateway.error.6002" : "The transaction you are trying to access has already been completed. Thank you.",
    "payment.gateway.error.6003" : "The transaction you are trying to access could not be found.",
    "payment.gateway.error.6004" : "The transaction you are trying to access has been canceled.",
    "payment.gateway.error.6005" : "The transaction you are trying to access has expired.",

    // Paysafe processing errors
    "payment.paysafe.processing.error.generic" : "The error seems to be due to the configuration of this transaction.",
    "payment.paysafe.processing.error.3002"    : "You submitted an invalid card number or brand or combination of card number and brand with your request.",
    "payment.paysafe.processing.error.3004"    : "The zip/postal code must be provided for an AVS check request.",
    "payment.paysafe.processing.error.3005"    : "You submitted an incorrect CVV value with your request.",
    "payment.paysafe.processing.error.3006"    : "You submitted an expired credit card number with your request.",
    "payment.paysafe.processing.error.3007"    : "Your request has failed the AVS check. Note that the amount has still been reserved on the customer's card and will be released in 3–5 business days. Please ensure the billing address is accurate before retrying the transaction.",
    "payment.paysafe.processing.error.3008"    : "You submitted a card type for which the merchant account is not configured.",
    "payment.paysafe.processing.error.3009"    : "Your request has been declined by the issuing bank.",
    "payment.paysafe.processing.error.3011"    : "Your request has been declined by the issuing bank because the card used is a restricted card. Contact the cardholder's credit card company for further investigation.",
    "payment.paysafe.processing.error.3012"    : "Your request has been declined by the issuing bank because the credit card expiry date submitted is invalid.",
    "payment.paysafe.processing.error.3013"    : "Your request has been declined by the issuing bank due to problems with the credit card account.",
    "payment.paysafe.processing.error.3014"    : "Your request has been declined - the issuing bank has returned an unknown response. Contact the card holder's credit card company for further investigation.",
    "payment.paysafe.processing.error.3015"    : "The bank has requested that you process the transaction manually by calling the cardholder's credit card company.",
    "payment.paysafe.processing.error.3016"    : "The bank has requested that you retrieve the card from the cardholder – it may be a lost or stolen card.",
    "payment.paysafe.processing.error.3017"    : "You submitted an invalid credit card number with your request.",
    "payment.paysafe.processing.error.3018"    : "The bank has requested that you retry the transaction.",
    "payment.paysafe.processing.error.3019"    : "Your request has failed the CVV check. Please note that the amount may still have been reserved on the customer's card, in which case it will be released in 3–5 business days.",
    "payment.paysafe.processing.error.3020"    : "The bank has requested that you retry the transaction.",
    "payment.paysafe.processing.error.3021"    : "The confirmation number included in this request could not be found.",
    "payment.paysafe.processing.error.3022"    : "The card has been declined due to insufficient funds.",
    "payment.paysafe.processing.error.3023"    : "Your request has been declined by the issuing bank due to its proprietary card activity regulations.",
    "payment.paysafe.processing.error.3024"    : "Your request has been declined because the issuing bank does not permit the transaction for this card.",
    "payment.paysafe.processing.error.3025"    : "The external processing gateway has reported invalid data.",
    "payment.paysafe.processing.error.3026"    : "The external processing gateway has reported the account type is invalid.",
    "payment.paysafe.processing.error.3027"    : "The external processing gateway has reported a limit has been exceeded.",
    "payment.paysafe.processing.error.3028"    : "The external processing gateway has reported a system error.",
    "payment.paysafe.processing.error.3029"    : "The external processing gateway has rejected the transaction.",
    "payment.paysafe.processing.error.3030"    : "The external processing gateway has reported the transaction is unauthorized.",
    "payment.paysafe.processing.error.3031"    : "The confirmation number you submitted with your request references a transaction that is not on hold.",
    "payment.paysafe.processing.error.3032"    : "Your request has been declined by the issuing bank or external gateway because the card is probably in one of their negative databases.",
    "payment.paysafe.processing.error.3035"    : "Your request has been declined due to exceeded PIN attempts.",
    "payment.paysafe.processing.error.3036"    : "Your request has been declined due to an invalid issuer.",
    "payment.paysafe.processing.error.3037"    : "Your request has been declined because it is invalid.",
    "payment.paysafe.processing.error.3038"    : "Your request has been declined due to customer cancellation.",
    "payment.paysafe.processing.error.3039"    : "Your request has been declined due to an invalid authentication value.",
    "payment.paysafe.processing.error.3040"    : "Your request has been declined because the request type is not permitted on the card.",
    "payment.paysafe.processing.error.3041"    : "Your request has been declined due to a timeout.",
    "payment.paysafe.processing.error.3042"    : "Your request has been declined due to a cryptographic error.",
    "payment.paysafe.processing.error.3044"    : "You have submitted a duplicate request.",
    "payment.paysafe.processing.error.3045"    : "You submitted an invalid date format for this request.",
    "payment.paysafe.processing.error.3046"    : "The transaction was declined because the amount was set to zero.",
    "payment.paysafe.processing.error.3047"    : "The transaction was declined because the amount exceeds the floor limit.",
    "payment.paysafe.processing.error.3048"    : "The transaction was declined because the amount is less than the floor limit.",
    "payment.paysafe.processing.error.3049"    : "The bank has requested that you retrieve the card from the cardholder – the credit card has expired.",
    "payment.paysafe.processing.error.3050"    : "The bank has requested that you retrieve the card from the cardholder – fraudulent activity is suspected.",
    "payment.paysafe.processing.error.3051"    : "The bank has requested that you retrieve the card from the cardholder – contact the acquirer for more information.",
    "payment.paysafe.processing.error.3052"    : "The bank has requested that you retrieve the card from the cardholder – the credit card is restricted.",
    "payment.paysafe.processing.error.3053"    : "The bank has requested that you retrieve the card from the cardholder – please call the acquirer.",
    "payment.paysafe.processing.error.3054"    : "The transaction was declined due to suspected fraud.",
    "payment.paysafe.processing.error.3055"    : "This type of transaction is not supported.",
    "payment.paysafe.processing.error.3056"    : "The original transaction type does not match.",
    "payment.paysafe.processing.error.3057"    : "Please tell cardholder to call the issuer – do not retry transaction.",

    // Paysafe tokenize errors
    "payment.paysafe.tokenize.error.9001" : "No connection to server, please try again. If the problem remains, please contact our support.",
    "payment.paysafe.tokenize.error.9002" : "Error communicating with server.",
    "payment.paysafe.tokenize.error.9003" : "There were errors with the payment information. Please review the marked fields.",
    "payment.paysafe.tokenize.error.9004" : "There was an error (9004), please contact our support.",
    "payment.paysafe.tokenize.error.9015" : "There was an error (9015), please contact our support.",
    "payment.paysafe.tokenize.error.9034" : "There was an error (9034), please contact our support.",
    "payment.paysafe.tokenize.error.9035" : "There was an error (9035), please contact our support.",
    "payment.paysafe.tokenize.error.9036" : "There was an error (9036), please contact our support.",
    "payment.paysafe.tokenize.error.9037" : "Error communicating with 3DS server.",
    "payment.paysafe.tokenize.error.9039" : "Enrollment is not accepted. Try again with the same or another card.",
    "payment.paysafe.tokenize.error.9040" : "Authentication not accepted. Try again with the same or another card.",
    "payment.paysafe.tokenize.error.9041" : "There was an error (9041), please contact our support.",
    "payment.paysafe.tokenize.error.9042" : "User aborted authentication.",
    "payment.paysafe.tokenize.error.9043" : "There was an error (9043), please contact our support.",
    "payment.paysafe.tokenize.error.9044" : "There was an error (9044), please contact our support.",
    "payment.paysafe.tokenize.error.9045" : "There was an error (9045), please contact our support.",
    "payment.paysafe.tokenize.error.9046" : "There was an error (9046), please contact our support.",
    "payment.paysafe.tokenize.error.9047" : "There was an error (9047), please contact our support.",
    "payment.paysafe.tokenize.error.9048" : "There was an error (9048), please contact our support.",
    "payment.paysafe.tokenize.error.9049" : "There was an error (9049), please contact our support.",
    "payment.paysafe.tokenize.error.9050" : "There was an error (9050), please contact our support.",
    "payment.paysafe.tokenize.error.9051" : "There was an error (9051), please contact our support.",
    "payment.paysafe.tokenize.error.9052" : "There was an error (9052), please contact our support.",
    "payment.paysafe.tokenize.error.9110" : "There was an error (9110), please contact our support.",
    "payment.paysafe.tokenize.error.9111" : "There was an error (9111), please contact our support.",
    "payment.paysafe.tokenize.error.9112" : "There was an error (9112), please contact our support.",
    "payment.paysafe.tokenize.error.9113" : "There was an error (9113), please contact our support.",
    "payment.paysafe.tokenize.error.9114" : "There was an error (9114), please contact our support.",
    "payment.paysafe.tokenize.error.9125" : "There was an error (9125), please contact our support.",

}