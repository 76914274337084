import React from "react";
import {
    Collapse,
	Button,
    Label,
    Row,
	Col,
    FormGroup
} from 'reactstrap';

import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { FormikCheckedButton, FormikGroup, FormikInputMasked, FormikInputNumber, FormikInputText, FormikRadioButton, FormikSelect, FormikPhoneInput } from "@spordle/formik-elements";

// Cusotm Elements
import Translate from '@spordle/intl-elements';
import Wrapper from "../../components/Wrapper";

// API
import { AccountCreation } from "../../contexts/AccountCreationContext";
import { AxiosCancelAll, AxiosIsCancelled } from "../../api/CancellableAPI";

const letterRegex = /[a-zA-Z]/;
const numberRegex = /\d/;
const zipRegex = [letterRegex, numberRegex, letterRegex, ' ', numberRegex, letterRegex, numberRegex];
const testingZipRegex = new RegExp(zipRegex.join('').replace(/\//gm, ''));

class AccountCreationStep1 extends React.PureComponent {
    static contextType = AccountCreation;

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    componentWillUnmount(){
        AxiosCancelAll()
    }

    scrollToError = () => {
        let elements = document.getElementsByClassName('error');

        if(elements.length !== 1){
            let posArray = [
                document.getElementById('mainTitle').getBoundingClientRect().top + window.pageYOffset,
                document.getElementById('buyerCC').getBoundingClientRect().top + window.pageYOffset,
                document.getElementById('accountDetails').getBoundingClientRect().top + window.pageYOffset,
                document.getElementById('merchantAddress').getBoundingClientRect().top + window.pageYOffset,
            ]

            let elementPos = elements[1].getBoundingClientRect().top + window.pageYOffset;
            for (let index = 0; index < posArray.length; index++) {
                let pos = posArray[index];
                let nextPos = posArray[index+1];
                if(nextPos){
                    if(elementPos > pos && elementPos < nextPos){
                        window.scrollTo({
                            top: pos,
                            behavior: 'smooth',
                        });
                        break;
                    }
                } else {
                    window.scrollTo({
                        top: pos,
                        behavior: 'smooth',
                    });
                    break;
                }
            }
            this.hasValidated = false;
        }
    }

    render() {
        const formatMessage = this.props.intl.formatMessage;
        return (
            /*--------------------------------------------------------------------------------*/
            /* Step 1                                                                         */
            /*--------------------------------------------------------------------------------*/
            <Formik
                initialValues={{
                    merchant_name: '',
                    legal_entity: '',
                    category: '',
                    url: '',
                    phone_customer_support: '',
                    locale: this.props.lang === 'fr' ? 'fr_CA' : 'en_CA',
                    email: '',
                    dynamic_descriptor: '',
                    phone: '',
                    type: '',
                    description: '',
                    federal_tax_number: '',
                    yearly_volume_range: '',
                    has_previously_processed_cards: '0',
                    ships_goods: '0',
                    delivery_time_range: 'SHORT',
                    refund_policy_description: '',
                    refund_policy: '0',
                    city: '',
                    street: '',
                    street2: '',
                    zip: '',
                    country: 'CA',
                    province: 'QC',
                    additional_payment_methods: ""
                }}
                validationSchema={Yup.object().shape({
                    merchant_name: Yup.string().required(formatMessage({id:'error.required'})),
                    legal_entity: Yup.string().required(formatMessage({id: 'error.required'})),
                    category: Yup.string().required(formatMessage({id: 'error.required'})),
                    url: Yup.string().required(formatMessage({id: 'error.required'})).url(formatMessage({id: 'error.url'})),
                    phone_customer_support: Yup.string().required(formatMessage({id: 'error.required'})).isValidPhoneNumber(formatMessage({id: 'error.phoneFormat'})),
                    locale: Yup.string().required(formatMessage({id: 'error.required'})),
                    email: Yup.string().required(formatMessage({id: 'error.required'})).email(formatMessage({id: 'error.email'})),
                    dynamic_descriptor: Yup.string().required(formatMessage({id: 'error.required'})).max(20, formatMessage({id: 'error.length'}, {length: 20})),
                    phone: Yup.string().required(formatMessage({id: 'error.required'})).isValidPhoneNumber(formatMessage({id: 'error.phoneFormat'})),
                    type: Yup.string().required(formatMessage({id: 'error.required'})),
                    description: Yup.string().required(formatMessage({id: 'error.required'})),
                    federal_tax_number: Yup.string().required(formatMessage({id: 'error.required'})).length(9, formatMessage({id: 'error.length'}, {length: 9})),
                    yearly_volume_range: Yup.string().required(formatMessage({id: 'error.required'})),
                    has_previously_processed_cards: Yup.string().required(formatMessage({id: 'error.required'})),
                    ships_goods: Yup.string().required(formatMessage({id: 'error.required'})),
                    delivery_time_range: Yup.string().required(formatMessage({id: 'error.required'})),
                    refund_policy: Yup.string().required(formatMessage({id: 'error.required'})),
                    city: Yup.string().required(formatMessage({id: 'error.required'})),
                    street: Yup.string().required(formatMessage({id: 'error.required'})),
                    zip: Yup.string().required(formatMessage({id: 'error.required'})).matches(testingZipRegex, formatMessage({id: 'error.zipFormat'})),
                    country: Yup.string().required(formatMessage({id: 'error.required'})),
                    province: Yup.string().required(formatMessage({id: 'error.required'})),
                })}
                onSubmit={(values, { setSubmitting, setStatus, setFieldError }) => {
                    setStatus();
                    const newValues = {
                        ...values,
                        additional_payment_methods: values.additional_payment_methods !== "" ? [values.additional_payment_methods] : []
                    }

                    this.context.step1(newValues, this.props)
                        .then(() => {
                            this.props.nextStep();
                        }).catch(error => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false);
                                if(Array.isArray(error)){
                                    error.forEach(fieldError => {
                                        switch (fieldError.field) {
                                            case 'category':
                                                setFieldError('category', fieldError.error);
                                                break;
                                            case 'email':
                                                setFieldError('email', fieldError.error);
                                                break;
                                            case 'caAccountDetails.type':
                                                setFieldError('type', fieldError.error);
                                                break;
                                            case 'caAccountDetails.federalTaxNumber':
                                                setFieldError('federal_tax_number', fieldError.error);
                                                break;
                                            case 'yearlyVolumeRange':
                                                setFieldError('yearly_volume_range', fieldError.error);
                                                break;
                                            case 'address.zip':
                                                setFieldError('zip', fieldError.error);
                                                break;
                                            case 'caAccountDetails.deliveryTimeRange':
                                                setFieldError('delivery_time_range', fieldError.error);
                                                break;
                                            case 'caAccountDetails.additionalPaymentMethods':
                                                setFieldError('additional_payment_methods', fieldError.error);
                                                break;
                                            default:
                                                break;
                                        }
                                    });
                                } else if(error.message === '8500' || error.message === '8502'){
                                    setFieldError('merchant_name', formatMessage({id: 'error.merchantName.inUse'}));
                                } else {
                                    console.error(error);
                                    setStatus(error.message);
                                }
                                this.scrollToError();
                            }
                        });
                }}
            >
                {formik => {
                    if(this.hasValidated){
                        this.scrollToError();
                    }
                    if(formik.isValidating){
                        this.hasValidated = true;
                    }
                    return (
                        <Form id='step1_form'>
                            {/* This div is needed for the scrollToError to work */}
                            <div className="error d-none"></div>
                            {/* SPORDLE ACCOUNT CREATION */}
                            <div id="mainTitle" className="h3 mb-2 font-bold text-center"><Translate id="mainTitle" values={{productName: this.props.productName}} /></div>
                            <p className="mb-4 text-center"><Translate id="SECURITY_REASON"/></p>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText trim label='MERCHANT_NAME' name="merchant_name" id="merchant_name" autoComplete="new-merchant_name"/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText trim label='LEGAL_ENTITY' name="legal_entity" id="legal_entity" autoComplete="new-legal_entity"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikSelect
                                            label='BUSINESS_CAT'
                                            name="category"
                                            id="category"
                                            search={false}
                                            placeholder={formatMessage({id: 'SELECT_ONE'})}
                                            defaultData={[
                                                { label: 'RECREATION_SERVICES', value: 'RECREATION', translateLabel:true },
                                                { label: 'CHARITY', value: 'CHARITY', translateLabel:true },
                                                { label: 'EDUCATION', value: 'EDU', translateLabel:true },
                                                { label: 'MEMBERSHIP_ORG', value: 'MEMBERSHIP', translateLabel:true },
                                            ]}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText trim label='ORGANISATION_WEBSITE_URL' type="url" name="url" id="url" helper='ORGANISATION_WEBSITE_URL_HELPER' autoComplete="new-url"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikPhoneInput label="PHONE_SUPPORT" name="phone_customer_support" id="phone_customer_support" />
                                    </Col>
                                    <Col md='6'>
                                        <Label htmlFor="legal_entity"><Translate id="LANG_CHOICE"/></Label>
                                        <FormikGroup name='locale'>
                                            <Col sm='12'>
                                                <FormikRadioButton id="locale_fr" name="locale" value='fr_CA' label='FR' defaultChecked={formik.initialValues.locale === 'fr_CA'}/>
                                            </Col>
                                            <Col sm='12'>
                                                <FormikRadioButton id="locale_en" name="locale" value='en_CA' label='EN' defaultChecked={formik.initialValues.locale === 'en_CA'}/>
                                            </Col>
                                        </FormikGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <FormikInputText label='ORGANISATION_EMAIL' type="email" name="email" id="email" trim/>
                            </FormGroup>

                            {/* BUYER'S CREDIT CARD STATEMENT */}
                            <div id="buyerCC" className="h3 mt-5 mb-4 font-bold text-center"><Translate id="LEGEND_ON_TRANSAC"/></div>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText label='DYNAMIC_DESC' maxLength="20" name="dynamic_descriptor" id="dynamic_descriptor" autoComplete="new-dynamic_descriptor" trim/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikPhoneInput label="PHONE" name="phone" id="phone" />
                                    </Col>
                                </Row>
                            </FormGroup>

                            {/* ACCOUNT DETAILS */}
                            <div id="accountDetails" className="h3 mt-5 mb-4 font-bold text-center"><Translate id="LEGEND_DETAILLED"/></div>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikSelect
                                            label='BUSINESS_TYPE' name="type" id="type"
                                            search={false}
                                            placeholder='SELECT_ONE'
                                            defaultData={[
                                                { label: 'CHARITY', value: 'CHARITY', translateLabel:true },
                                                { label: 'CIC', value: 'CIC', translateLabel:true },
                                                { label: 'CORP', value: 'CORP', translateLabel:true },
                                                { label: 'LTD', value: 'LTD', translateLabel:true },
                                                { label: 'NPCORP', value: 'NPCORP', translateLabel:true },
                                                { label: 'PARTNERSHIP', value: 'PARTNERSHIP', translateLabel:true },
                                                { label: 'SOLE_PROP', value: 'SOLE_PROP', translateLabel:true },
                                            ]}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText label='BUSINESS_DESC' name="description" id="description" autoComplete="new-description" trim helper='BUSINESS_DESC_EX' maxLength='255'/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputNumber allowNegative={false} maxLength="9" placeholder="FEDERAL_TAX_NUMBER_PLACEHOLDER" allowLeadingZeros label="FEDERAL_TAX_NUMBER" name="federal_tax_number" id="federal_tax_number" autoComplete="new-federal_tax_number" helper='NO_NUMBER_0'/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikSelect
                                            label='YEARLY_VOLUME_RANGE' name="yearly_volume_range" id="yearly_volume_range"
                                            search={false}
                                            placeholder='SELECT_ONE'
                                            defaultData={[
                                                { label: 'LOW', value: 'LOW', translateLabel:true },
                                                { label: 'MEDIUM', value: 'MEDIUM', translateLabel:true },
                                                { label: 'HIGH', value: 'HIGH', translateLabel:true },
                                                { label: 'VERY_HIGH', value: 'VERY_HIGH', translateLabel:true },
                                            ]}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="has_previously_processed_cards"><Translate id="HAS_PROCESSED_B4"/></Label>
                                <FormikGroup name="has_previously_processed_cards">
                                    <Col sm='12'>
                                        <FormikRadioButton id="process_no" name="has_previously_processed_cards" label='NO' value='0' defaultChecked={formik.initialValues.has_previously_processed_cards === '0'}/>
                                    </Col>
                                    <Col sm='12'>
                                        <FormikRadioButton id="process_yes" name="has_previously_processed_cards" label='YES' value='1' defaultChecked={formik.initialValues.has_previously_processed_cards === '1'}/>
                                    </Col>
                                </FormikGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="ships_goods"><Translate id="SHIPS_GOODS"/></Label>
                                <FormikGroup name="ships_goods">
                                    <Col sm='12'>
                                        <FormikRadioButton id="ship_no" name="ships_goods" label='NO' value='0' defaultChecked={formik.initialValues.ships_goods === '0'}/>
                                    </Col>
                                    <Col sm='12'>
                                        <FormikRadioButton id="ship_yes" name="ships_goods" label='YES' value='1' defaultChecked={formik.initialValues.ships_goods === '1'}/>
                                    </Col>
                                </FormikGroup>
                            </FormGroup>
                            <Collapse isOpen={formik.values.ships_goods === "1"}>
                                <FormGroup>
                                    <FormikSelect
                                        label='YEARLY_VOLUME_RANGE' name="yearly_volume_range" id="yearly_volume_range"
                                        search={false}
                                        placeholder='SELECT_ONE'
                                        defaultData={[
                                            { label: '1_7_DAYS', value: '1_7_DAYS', translateLabel:true },
                                            { label: '8_14_DAYS', value: '8_14_DAYS', translateLabel:true },
                                            { label: '15_DAYS_MORE', value: '15_DAYS_MORE', translateLabel:true },
                                        ]}
                                        renderOption={(option) => <Translate id={option.option.label} />}
                                        loadingStatus="success"
                                    />
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label htmlFor="refund_policy"><Translate id="REFUND_POLICY"/></Label>
                                <FormikGroup name="refund_policy">
                                    <Col sm='12'>
                                        <FormikRadioButton id="refund_no" name="refund_policy" label='NO' value='0' defaultChecked={formik.initialValues.refund_policy === '0'}/>
                                    </Col>
                                    <Col sm='12'>
                                        <FormikRadioButton id="refund_yes" name="refund_policy" label='YES' value='1' defaultChecked={formik.initialValues.refund_policy === '1'}/>
                                    </Col>

                                </FormikGroup>
                            </FormGroup>
                            <Collapse isOpen={formik.values.refund_policy === "1"}>
                                <FormGroup>
                                    <FormikInputText label='REFUND_POLICY_DESC' maxLength='255' trim name="refund_policy_description" id="refund_policy_description" autoComplete="new-refund_policy_description"/>
                                </FormGroup>
                            </Collapse>
                            <FormGroup>
                                <Label htmlFor="additional_payment_methods"><Translate id='ADDITIONAL_PAYMENT_METHOD'/></Label>
                                <FormikGroup name="additional_payment_methods">
                                    <Col sm='12'>
                                        <FormikRadioButton id="additional_payment_methods_no" name="additional_payment_methods" label='NO' value="" defaultChecked={formik.initialValues.additional_payment_methods === ""}/>
                                    </Col>
                                    <Col sm='12'>
                                        <FormikRadioButton id="additional_payment_methods_yes" name="additional_payment_methods" label='YES' value="VISA_DEBIT" defaultChecked={formik.initialValues.additional_payment_methods === "VISA_DEBIT"}/>
                                    </Col>
                                </FormikGroup>
                            </FormGroup>

                            {/* MERCHANT ADDRESS */}
                            <div id="merchantAddress" className="h3 mt-5 mb-4 font-bold text-center"><Translate id="LEGEND_MERCHANT_ADDRESS"/></div>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText label='STREET' trim name="street" id="street" autoComplete="new-street"/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText label='STREET_2' trim name="street2" id="street2" autoComplete="new-street2"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText label='CITY' trim name="city" id="city" autoComplete="new-city"/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText label='ZIP' trim name="zip" id="zip" autoComplete="new-city"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikSelect
                                            name="country" id="country" label='COUNTRY'
                                            search={false}
                                            placeholder='SELECT_ONE'
                                            defaultData={[
                                                { label: 'Canada', value: 'CA', translateLabel: true },
                                            ]}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                    <Col md='6'>
                                        <FormikSelect
                                            name="province" id="province" label='STATE'
                                            search={false}
                                            placeholder='SELECT_ONE'
                                            defaultData={[
                                                { label: 'Alberta', value: 'AB', translateLabel:false },
                                                { label: 'BRITISH_COLUMBIA', value: 'BC', translateLabel:true },
                                                { label: 'Manitoba', value: 'MB', translateLabel:false },
                                                { label: 'NEW_BRUNSWICK', value: 'NB', translateLabel:true },
                                                { label: 'NEWFOUNDLAND', value: 'NL', translateLabel:true },
                                                { label: 'NOVA_SCOTIA', value: 'NS', translateLabel:true },
                                                { label: 'NORTHWEST_TERRITORIES', value: 'NT', translateLabel:true },
                                                { label: 'Nunavut', value: 'NU', translateLabel:false },
                                                { label: 'Ontario', value: 'ON', translateLabel:false },
                                                { label: 'PRINCE_EDWARD_ISLAND', value: 'PE', translateLabel:true },
                                                { label: 'QUEBEC', value: 'QC', translateLabel:true },
                                                { label: 'Saskatchewan', value: 'SK', translateLabel:false },
                                                { label: 'Yukon', value: 'YT', translateLabel:false },
                                            ]}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            {formik.status &&
                                <div className={'alert alert-danger'}><Translate id='error.api'/></div>
                            }
                            <div className="mt-4 text-right">
                                <Button color="info" className="btn btn-info" type="submit" disabled={formik.isSubmitting}><Translate id="NEXT_STEP"/></Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        );
    }
}

export default (props) => <Wrapper {...props} JsxElement={AccountCreationStep1}/>;