/* eslint-disable react/no-array-index-key */
import { useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../contexts/PaymentContext";
import { I18nContext } from "../../contexts/I18nContext";
import { Card, Collapse, Fade, Spinner } from "reactstrap";
import Translate from "@spordle/intl-elements";

import PaymentPreview from "./components/PaymentPreview";
import PaymentBilling from "./components/PaymentBilling";
import PaymentCard from "./components/PaymentCard";
import PaymentReview from "./components/PaymentReview";
import PaymentConfirmation from "./components/PaymentConfirmation";

const PaymentWizard = (props) => {
    const { getGenericLocale } = useContext(I18nContext);
    const {
        isLoading,
        currentStep,
        completedSteps,
        setCurrentStep,
        error,
        setError,
        steps,
        paymentInfo,
    } = useContext(PaymentContext);
    const [ showSpordleImg, setShowSpordleImg ] = useState(!paymentInfo?.logo_url);

    useEffect(() => {
        setShowSpordleImg(!paymentInfo?.logo_url);
    }, [ paymentInfo?.logo_url ])

    const getExtraClass = (index) => {
        if(index < currentStep || (completedSteps && index < steps.length && index !== currentStep)) // Already done
            return { class: `is-done`, clickable: true };
        else if(index === currentStep) // Current
            return { class: `${completedSteps ? 'is-done text-dark' : 'is-active'}`, clickable: false };
        return { class: '', clickable: false };
    }

    /***********************************************************************************************************************
    /* Steps
    /***********************************************************************************************************************/
    const displayStep = () => {
        if(isLoading){
            return (
                <Fade>
                    <Card className="mx-auto text-center align-items-center justify-content-around" style={{ width: 500, height: 500 }}>
                        <Spinner />
                    </Card>
                </Fade>
            )
        }

        if(error){
            return (
                <Fade>
                    <Card className="mx-auto text-center align-items-center justify-content-around p-5" style={{ width: 500, height: 500 }}>
                        <div className="h4"><Translate id={`payment.gateway.error.${error}`} /></div>
                    </Card>
                </Fade>
            )
        }

        switch (currentStep){
            case 1:
                return <PaymentPreview />;
            case 2:
                return <PaymentBilling />;
            case 3:
                return <PaymentCard />;
            case 4:
                return <PaymentReview checkout={checkout} />;
            case 5:
                return <PaymentConfirmation />;
            default:
                return (
                    <Fade>
                        <Card className="mx-auto text-center align-items-center justify-content-around" style={{ width: 500, height: 500 }}>
                            <Spinner />
                        </Card>
                    </Fade>
                );
        }
    }

    const checkout = () => {
        setCurrentStep(steps.length + 1); // Go directly to last step
    }


    useEffect(() => {
        setError(null);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <>
            {(currentStep <= steps.length && !error) ?
                <ol id="formStep" className="form-steps mb-5 mx-auto d-flex" style={{ maxWidth: 500 }}>
                    {steps.map((step, index) => {
                        const specification = getExtraClass(index + 1);
                        return (
                            <li key={index} className={`form-steps-item ${specification.class}`} onClick={(specification.clickable) ? () => setCurrentStep(index + 1) : null}>
                                <span className="form-steps-number">{index + 1}</span>
                                <Collapse isOpen={currentStep >= 1}><span className="form-steps-text">{step[getGenericLocale()]}</span></Collapse>
                            </li>
                        )
                    })}
                </ol>
                :
                ''}

            <Collapse isOpen={currentStep > 1}>
                <Card body className="mx-auto border card-shadow p-3 mb-3" style={{ maxWidth: 500 }}>
                    {currentStep > 1 &&
                        <>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center mr-auto text-truncate">
                                    <div className="mr-3">
                                        <img
                                            src={showSpordleImg ? 'https://www.spordle.com/img/favicon/favicon.ico' : paymentInfo.logo_url}
                                            onError={() => {
                                                setShowSpordleImg(true);
                                            }}
                                            alt="user"
                                            className="rounded-circle shadow"
                                            width="50"
                                        />
                                    </div>
                                    <div>
                                        {paymentInfo && paymentInfo.title ? <div className="h5 mb-1 font-bold">{paymentInfo.title}</div> : ''}
                                        {paymentInfo && paymentInfo.transaction_id ? <div className="mb-1">#{paymentInfo.transaction_id}</div> : ''}
                                    </div>
                                </div>
                                <div className="h5 font-bold text-nowrap ml-2">
                                    <Translate id="misc.priceValue" values={{ price: (paymentInfo.total_amount / 100).toFixed(2) }} />
                                </div>
                            </div>
                            {currentStep === 4 &&
                                paymentInfo && paymentInfo.description ? <div className="h6 mb-0 font-medium" dangerouslySetInnerHTML={{ __html: paymentInfo.description }} /> : ''
                            }
                        </>
                    }
                </Card>
            </Collapse>

            <Card className="mx-auto card-shadow" style={{ maxWidth: 500 }}>
                {displayStep()}
            </Card>
        </>
    );

}

export default PaymentWizard;
