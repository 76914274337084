import React from "react";
import {
    Spinner,
    Button
} from 'reactstrap';

// Form validation
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import Wrapper from "../../components/Wrapper";

// Cusotm Elements
import Translate from '@spordle/intl-elements';

// API
import { AccountCreation } from "../../contexts/AccountCreationContext";
import { AxiosCancelAll, AxiosIsCancelled } from "../../api/CancellableAPI";

class AccountCreationStep6 extends React.PureComponent {
    static contextType = AccountCreation;

    goBackToReferrer = () => {
        if(this.props.user_data?.referrer){
            window.location.href = this.props.user_data.referrer;
        }
        else{
            switch (this.props.product) {
                case 'ENR':
                    window.location.href = 'https://app.eventnroll.com';
                    break;
                default:
                    window.location.href = 'https://spordle.com';
                    break;
            }
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    componentWillUnmount(){
		AxiosCancelAll();
	}

    render() {
        const formatMessage = this.props.intl.formatMessage;
        return (
            /*--------------------------------------------------------------------------------*/
            /* Step 6                                                                         */
            /*--------------------------------------------------------------------------------*/
            <Formik
                initialValues={{
                    fee_selection: '',
                    accountCreated: false
                }}
                validationSchema={Yup.object().shape({
                    fee_selection: Yup.string().test({
                        name: 'fee_selection_required',
                        message: formatMessage({id: 'error.required'}),
                        test: () => {
                            /*if(this.props.product === 'TSI'){
                                return value.length !== 0;
                            }*/
                            return true;
                        }
                    }),
                })}
                onSubmit={(_values, { setSubmitting, setStatus, setFieldValue }) => {
                    setStatus();

                    this.context.activateAccount(this.props.product, this.props.ref_id)
                        .then(() => {
                            setFieldValue('accountCreated', true);
                            setTimeout(() => {
                                this.goBackToReferrer();
                            }, 5000);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false);
                                console.error(error);
                                setStatus(error.message);
                            }
                        });
                }}
            >
                {formik => (
                    <Form id='activate_form'>
                        <div className="h3 mt-2 mb-4 font-bold text-center">
                            {/*this.props.product !== 'TSI' ? translate("ACTIVATE_TEXT") : translate("ACTIVATE_TEXT_TSI")*/}
                            <Translate id="ACTIVATE_TEXT"/>
                        </div>
                        {/*<FormGroup className={this.props.product !== 'TSI' ? 'd-none' : ''}>
                            <FormikSelect name="fee_selection" id="fee_selection" label='FEE_SELECTION' isSearchable={false} placeholder={formatMessage({id: 'SELECT_ONE')} noOptionsMessage={() => translate('FORMIK_SELECT_NO_OPTION')}>
                                <SpordleSelect.Option id='ORG_ABSORB' label='ORG_ABSORB' translateLabel value='ORG_ABSORB'/>
                                <SpordleSelect.Option id='SPLIT_ABSORB' label='SPLIT_ABSORB' translateLabel value='SPLIT_ABSORB'/>
                                <SpordleSelect.Option id='CLIENT_ABSORB' label='CLIENT_ABSORB' translateLabel value='CLIENT_ABSORB'/>
                            </FormikSelect>
                        </FormGroup>*/}

                        <div className={`text-center ${!formik.values.accountCreated ? '' : 'd-none'}`}>
                            <Button color='success' type="submit" disabled={formik.isSubmitting}><Translate id="ACTIVATE"/></Button>
                        </div>

                        <div className={`text-center ${formik.values.accountCreated ? '' : 'd-none'}`}>
                            <div className="h2 mt-2 mb-4 font-bold text-center text-success">
                                <Translate id="SUMMARY_TEXT"/>
                            </div>
                            <div className="h4 mt-2 mb-4 font-bold text-center">
                                <Translate id="redirectNotice"/>
                            </div>
                            <div className="text-center mt-5">
                                <Spinner type="grow" color="success" />
                            </div>
                            {/*<div className="text-center mt-5">
                                <Button outline color='success' onClick={this.goBackToReferrer}>
                                    <Translate id="manualRedirect", {productName: this.getRefererName()})}
                                </Button>
                            </div>*/}
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default (props) => <Wrapper {...props} JsxElement={AccountCreationStep6}/>;