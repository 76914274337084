import API_UTILS from '../API-Utils';
import { serverError } from "../CancellableAPI";

/**
 * Returns all countries from the state or makes an API call to get them
 */
export function getCountries(){
    return API_UTILS.get(`/utils/countries`)
        .then((response) => {
            if(response.data.status){
                return response.data.countries;
            }
            throw response.data.errors;
        }, serverError);

}
