export default {
    'mainTitle'                         : 'Création du compte marchand {productName}',
    'stepTitle'                         : 'Étape {step} de 5',

    'redirectNotice'                    : 'Vous serez redirigé dans quelques instants.',
    'manualRedirect'                    : 'Cliquez ici pour revenir sur {productName}',
    'return_to_referer'                 : 'Revenir sur {productName}',


    'ACCOUNT_NUMBER_HELPER'             : '5 à 12 chiffres',
    'TRANSIT_NUMBER_HELPER'             : '5 chiffres',
    'INSTITUTION_ID_HELPER'             : '3 chiffres',
    '1_7_DAYS'                          : '1 à 7 jours',
    '8_14_DAYS'                         : '8 à 14 jours',
    '15_DAYS_MORE'                      : '15 jours et plus',
    'ACCOUNT_NUMBER'                    : 'Numéro de Compte',
    'ACTIVATE'                          : 'Activer',
    'ACTIVATE_TEXT'                     : 'La création de votre compte est presque terminé, cliquer sur "Activer" pour lancer le processus d\'approbation. Ce prossessus peut prendre 24h-72h.',
    'ACTIVATE_TEXT_TSI'                 : 'La création de votre compte est presque terminé, faites votre choix de type de facturation et cliquer sur "Activer" pour lancer le processus d\'approbation. Ce prossessus peut prendre 24h-72h.',
    'ANSWER'                            : 'Réponse',
    'BACK_TO_LIST'                      : 'Retour à la liste des comptes',
    'BRITISH_COLUMBIA'                  : 'Colombie-Britanique',
    'BUSINESS_CAT'                      : 'Catégorie d\'Entreprise / Organisation',
    'BUSINESS_DESC'                     : 'Description',
    'BUSINESS_DESC_EX'                  : 'ex : Organisation Sportive',
    'BUSINESS_TYPE'                     : 'Type d\'Entreprise / Organisation',
    'CHARITY'                           : 'Charité',
    'CIC'                               : 'Société d\'intérêt communautaire',
    'CITY'                              : 'Ville',
    'CLOSE_WINDOW'                      : 'Fermer la fenêtre',
    'CORP'                              : 'Corporation',
    'COUNTRY'                           : 'Pays',
    'CONTACT_SPORDLE'                   : 'Le système de sécurité à détecté que vous ne devriez pas avoir accès a créer un compte pour cette organisation. Si vous croyez qu\'il s\'agit d\'une erreur communiquez avec Spordle.',
    'DATE_FORMAT'                       : 'AAAA-MM-JJ',
    'DATE_OF_BIRTH'                     : 'Date de Naissance',
    'DELIVERY_TIME_RANGE'               : 'Veuillez indiquer le temps approx. de livraison',
    'DIRECT_DEBIT'                      : 'Débit direct',
    'DIRECT_VISA_DEBIT'                 : 'Débit directe et Visa Débit',
    'DYNAMIC_DESC'                      : 'Description (maximum 20 caractères, avec les espaces)',
    'EDUCATION'                         : 'Éducation',
    'EMAIL'                             : 'Adresse courriel',
    'EN'                                : 'Anglais',
    'END_PROCESS'                       : 'Terminer la création du compte',
    'ERROR_DIALOG_CONTENT_MESSAGE'      : '<div class="small-form-group">Une erreur est survenue lors de la sauvegarde au niveau de Paysafe.</div><div class="small-form-group">Si jamais vous n\'êtes pas en mesure de trouver votre erreur, veuillez nous contacter à <b>support@spordle.com</b>.</div><div class="small-form-group">Veuillez nous fournir votre <b>nom</b>, <b>prénom</b>, <b># de téléphone</b> ainsi que <b>l\'organisation</b> pour laquel vous essayer de créer votre compte. Pour accélerer la demande de support, vous pouvez aussi nous fournir une capture d\'écran des messages d\'erreur que vous avez reçu.</div><div class="small-form-group">Merci,</div><div class="small-form-group">&nbsp;</div><div class="small-form-group">Voici le ou les code(s) d\'erreur qui sont retournées.</div>',
    'E_ACCOUNT_CREATION'                : 'Erreur lors de la création du Compte Marchand',
    'E_ACTIVATE_TEXT'                   : 'La demande d\'activation de compte que vous avez soumise semble invalide ou contient des erreurs, veuillez contacter Spordle.',
    'E_ACTIVATE_TITLE'                  : 'Erreur d\'activation',
    'E_BANK_ACCOUNT'                    : 'Erreur lors de la sauvegarde des informations banquaires',
    'E_BUSINESS_OWNER'                  : 'Erreur lors de la sauvegarde de la personne en charge',
    'E_CANT_REACH_TEXT'                 : 'La connexion avec Paysafe n\'a pas pu être établi. Veuillez réessayer plus tard. Pour plus d\'information, veuillez communiquer avec Spordle',
    'E_CANT_REACH_TITLE'                : 'Erreur de connexion',
    'E_CREATE_USER'                     : 'Erreur lors de la création de l\'usager',
    'E_TERMS_AND_COND'                  : 'Erreur durant l\'acceptation des termes et conditions',
    'E_required_field'                  : 'Le champs %s est obligatoire.',
    'E_max_lenght'                      : 'Le champs {field} doit avoir maximum de {param} caractère.',
    'E_numeric'                         : '{field} doit contenir seulement des chiffres.',
    'FEDERAL_TAX_NUMBER'                : 'Numéro d\'identification / enregistrement d\'impôt de l\'entreprise.',
    'FEDERAL_TAX_NUMBER_PLACEHOLDER'    : '123456789',
    'FIELD'                             : 'Champs',
    'FILL_REQUIRED_FIELDS'              : 'Veuillez-vous assurer de remplir tout les champs obligatoires.',
    'FIRST_NAME'                        : 'Prénom',
    'FR'                                : 'Français',
    'GENERIC_ERROR_MESSAGE'             : '<div class="small-form-group">Une erreur est survenue lors de la sauvegarde au niveau de Paysafe.</div><div class="small-form-group">Si jamais vous n\'êtes pas en mesure de trouver votre erreur, veuillez nous contacter à <b>support@spordle.com</b>.</div><div class="small-form-group">Veuillez nous fournir votre <b>nom</b>, <b>prénom</b>, <b># de téléphone</b> ainsi que <b>l\'organisation</b> pour laquel vous essayer de créer votre compte.Pour accélerer la demande de support, vous pouvez aussi nous fournir une capture d\'écran des messages d\'erreur que vous avez reçu.</div><div class="small-form-group"> Merci,</div><div class="small-form-group">&nbsp;</div> <div class="small-form-group"> Voici le ou les code(s) d\'erreur qui sont retournées.</div>',
    'HAS_PROCESSED_B4'                  : 'Avez-vous déjà pris en charge des transactions crédit?',
    'HIGH'                              : 'Entre 100 000$ et 250 000$',
    'INSTITUTION_ID'                    : 'Numéro d\'Institution',
    'JOB_TITLE'                         : 'Poste au sein de l\'Entreprise / Organisation',
    'LANG_CHOICE'                       : 'Choix de langue',
    'LAST_NAME'                         : 'Nom de famille',
    'LEGAL_ENTITY'                      : 'Nom légal de l\'Entreprise / Organisation',
    'LEGEND_BANK_ACCOUNT_DETAILS'       : 'Information Bancaire',
    'LEGEND_BASIC'                      : 'Information de base du compte',
    'LEGEND_BUSINESS_OWNER'             : 'Information de la personne en charge',
    'LEGEND_BUSINESS_OWNER_CURR_ADDRESS': 'Lieu de résidence de la personne en charge',
    'LEGEND_BUSINESS_OWNER_PREV_ADDRESS': 'Dernier lieu de résidence de la personne en charge',
    'LEGEND_CREATE_USER'                : 'Création de l\'usager',
    'LEGEND_CREATE_USER_DESC'           : 'Cet usager aura accès au niveau administratif de Paysafe.',
    'LEGEND_DETAILLED'                  : 'Information détaillée du compte',
    'LEGEND_MERCHANT_ADDRESS'           : 'Adresse du Marchand',
    'LEGEND_ON_TRANSAC'                 : 'Informations à afficher sur la "Transaction Crédit" - Relevé carte de crédit de l\'acheteur',
    'LEGEND_TERMS_AND_CONDITIONS'       : 'Termes et conditions',
    'LOW'                               : 'Entre 0 et 50 000$',
    'LTD'                               : 'Limité',
    'MEDIUM'                            : 'Entre 50 000$ et 100 000$',
    'MEMBERSHIP_ORG'                    : 'Organisation d\'appartenance',
    'MERCHANT_NAME'                     : 'Nom du compte marchant',
    'NEWFOUNDLAND'                      : 'Terre-Neuve',
    'NEW_BRUNSWICK'                     : 'Nouveau-Brunswick',
    'NEXT_STEP'                         : 'Étape suivante',
    'NO'                                : 'Non',
    'NONE'                              : 'Aucun',
    'NONE_OF_BOTH'                      : 'Aucun des deux',
    'NORTHWEST_TERRITORIES'             : 'Territoires du Nord-Ouest',
    'NOVA_SCOTIA'                       : 'Nouvelle-Écosse',
    'NO_NUMBER_0'                       : 'Si vous n\'avez pas de numéro indiqué 000000000',
    'NPCORP'                            : 'Société à but non lucratif',
    'ORG_CHOICE'                        : 'Choix de l\'organisation',
    'ORGANISATION_EMAIL'                : 'Courriel de l\'organisation',
    'VALID_EMAIL'                       : 'Veuillez entrer un courriel valide',
    'ORGANISATION_WEBSITE_URL'          : 'Site web de l\'organisation',
    'ORGANISATION_WEBSITE_URL_HELPER'   : 'ex: https://www.site.com',
    'PARTNERSHIP'                       : 'Société de partenariat',
    'PASSWORD'                          : 'Mot de Passe',
    'PERCENTAGE_OWNERSHIP'              : 'Propriété en pourcentage de l\'applicant',
    'PHONE'                             : 'Numéro de téléphone',
    'PHONE_OWNER'                       : 'Numéro de téléphone',
    'PHONE_SUPPORT'                     : 'Numéro de téléphone (Support à vos clients)',
    'PHONE_SUPPORT_HELPER'              : 'ex: 555 888-9999',
    'PRINCE_EDWARD_ISLAND'              : 'Îles du Prince Édouard',
    'powred_by'                         : 'Propulsé par',
    'QUEBEC'                            : 'Québec',
    'question_bank_account'             : 'Quels sont les quatre derniers chiffres du numéro de votre compte bancaire?',
    'question_bank_city'                : 'Dans quelle ville se trouve votre succursale bancaire?',
    'question_business_city_year'       : 'Dans quelle ville et en quelle année votre entreprise a-t-elle été fondée? (p. ex., Montreal 1999)',
    'question_first_car'                : 'Quels sont la marque et le modèle de votre première voiture?',
    'question_first_pet'                : 'Quel est le nom de votre premier animal de compagnie?',
    'question_high_school_graduate'     : 'En quelle année et à quelle école avez-vous obtenu votre diplôme d\'études secondaires?',
    'question_mobile_provider'          : 'Qui est votre fournisseur de téléphone cellulaire et quel est votre numéro?',
    'question_oldest_sibling'           : 'Parmi vos frères et soeurs, quel est le nom du plus vieux?',
    'question_sum_birth'                : 'Quelle est la somme des chiffres de votre année de naissance? (p. ex., 1999, alors 1+9+9+9=28)',
    'question_youngest_sibling'         : 'Parmi vos frères et soeurs, quel est le nom du plus jeune?',
    'READ_AND_AGREE_TERMS'              : 'J\'ai lu et j\'accepte les termes et conditions',
    'RECOVERY_QUESTION'                 : 'Question secrète',
    'RECREATION_SERVICES'               : 'Service de loisirs',
    'REFUND_POLICY'                     : 'Avez-vous une politique de remboursement?',
    'REFUND_POLICY_DESC'                : 'Si oui, pouvez-vous la décrire? ( maximum 255 caractères )',
    'ADDITIONAL_PAYMENT_METHOD'         : 'Souhaitez-vous appliquer pour Visa et Mastercard Debit?',
    'ADDITIONAL_PAYMENT_METHOD_VISA_DEBIT': 'Visa / Débit',
    'REFUND_TERMS'                      : 'Pour des raisons de sécurité, les comptes marchands Paysafe sont configurés pour faire un paiement par semaine, le vendredi soir,  dans votre compte de banque.',
    'REGION_CHOICE'                     : 'Choix de la région',
    'SECURITY_REASON'                   : 'Une fois qu\'une étape est complété, vous ne pourrez plus revenir en arrière, Spordle ne conserve aucune information de ce formulaire pour des raisons de sécurité. Assurez-vous de la validité des informations avant de passer à l\'étape suivante.',
    'SHIPS_GOODS'                       : 'Avez-vous déjà fait de la livraison de bien?',
    'SOLE_PROP'                         : 'Propriétaire unique',
    'SELECT_ONE'                        : 'Veuillez sélectionner...',
    'SPECS_PASSWORD'                    : 'Le mot de passe doit contenir au moins 8 caractères.\nPeut contenir des lettres et des chiffres.\nDoit contenir au moins une majuscule et une minuscule.',
    'SPECS_PASSWORD1'                   : 'Le mot de passe doit contenir au moins 8 caractères.',
    'SPECS_PASSWORD2'                   : 'Peut contenir des lettres et des chiffres.',
    'SPECS_PASSWORD3'                   : 'Doit contenir au moins une majuscule et une minuscule.',
    'SPECS_USERNAME'                    : 'Le nom d\'usager doit être entre 2 et 64 caractères.\n Peut contenir des lettres et des chiffres.\nLes lettres doivent tous être en minuscule.\n ne peut pas contenir d\'espace.',
    'SPECS_USERNAME1'                   : 'Le nom d\'usager doit être entre 2 et 64 caractères.',
    'SPECS_USERNAME2'                   : 'Peut contenir des lettres et des chiffres.',
    'SPECS_USERNAME3'                   : 'Les lettres doivent tous être en minuscule.',
    'SPECS_USERNAME4'                   : 'Ne peut pas contenir d\'espace.',
    'SSN'                               : '*Numéro d\'Assurance Sociale',
    'SSN_NOT_REQUIRED'                  : '*Numéro d\'Assurance Sociale (pas obligatoire)',
    'STATE'                             : 'Province',
    'FORMIK_SELECT_NO_OPTION'           : 'Aucun résultat',
    'STREET'                            : 'Adresse (Numéro de porte, rue)',
    'STREET_2'                          : 'Adresse 2 (Numéro App., si nécessaire)',
    'SUMMARY_TEXT'                      : 'Merci, la création de votre compte est complété.',
    'TITLE'                             : 'Création du compte marchand',
    'TRANSIT_NUMBER'                    : 'Numéro de Transit',
    'UNDERSTAND'                        : 'Je comprends',
    'USER_NAME'                         : 'Nom d\'usager',
    'VERY_HIGH'                         : '250 000$ et plus',
    'VISA_DEBIT'                        : 'Visa Débit',
    'WARNING'                           : 'Attention',
    'WEBSITE_URL'                       : 'Site web',
    'YEARLY_VOLUME_RANGE'               : 'Revenu annuel approximatif',
    'YEARS_AT_ADDRESS'                  : 'Vous habitez à cette adresse depuis combien d\'année?',
    'YEARS_AT_ADDRESS_BEFORE'           : 'Combien d\'année avez-vous vécu à cette adresse?',
    'YES'                               : 'Oui',
    'ZIP'                               : 'Code Postal',
    'ZIP_PLACEHOLDER'                   : 'H7H 7H7',

    //field
    'ref_id'                            :'# Reference d\'organisation',
    'product'                           :'Produit',
    'Merchant_name'                     :'Nom du marchant',
    'legalEntity'                       :'Nom legal',
    'category'                          :'Catégorie d\'Organisation ',
    'phone'                             :'Téléphone',
    'description'                       :'Description',
    'org_type'                          :'Type d\'organisation',
    'address'                           :'Adresse',
    'city'                              :'Ville',
    'zip'                               :'Code postal',
    'yearlyVolumeRange'                 :'Revenu annuel approximatif',
    'dynamicDescriptor'                 :'Description de transaction crédit',
    'first_name'                        :'Prénom',
    'last_name'                         :'Nom',
    'job_title'                         :'Rôle',
    'phone_owner'                       :'Téléphone',
    'date_of_birth'                     :'Date de naissance',
    'date_of_birth_helper'              : 'ex: YYYY-MM-DD',
    'street_owner'                      :'Adresse',
    'zip_owner'                         :'Code postal',
    'years_at_address'                  :'Cette information',
    'percentage_ownership'              :'Le pourcentage de propriété',

    //fee absorbtion
    'FEE_SELECTION'                     :'Type de facturation',
    'CLIENT_ABSORB'                     :'Ajouter la totalité des frais (Frais de traitement et frais technologiques) au coût de l’inscription du joueur. (Ces coûts sont considérés comme étant des frais de service sur la facture des acheteurs).',
    'ORG_ABSORB'                        :'Absorber l’entièreté des frais (Frais de traitement et frais technologiques) à même le coût.',
    'SPLIT_ABSORB'                      :'Absorber les frais de traitement (2,45%) et additionner les frais technologiques au coût d’inscription (payé par le joueur)',
}