import AccountCreation from '../views/accountCreation/accountCreation';
import Payment from '../views/payment/Payment';

const ThemeRoutes = [
    {
        path: '/accountCreation',
        name: 'Merchant Account Creation',
        icon: 'ti-dashboard',
        component: AccountCreation,
    },
    {
        path: '/payment/:token',
        name: 'Payment',
        icon: 'ti-dashboard',
        component: Payment,
    },
    { path: '/', pathTo: '/home', name: 'home', redirect: true },
];
export default ThemeRoutes;
