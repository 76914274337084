import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        // authorised so return component
        <Component {...props} />
    )} />
)

export default PublicRoute;