import React, { Component, createContext } from 'react';

// User spaces
export const ACCOUNT = 'ACCOUNT';
export const FEDERATION = 'PAYMENT';

/**
* **********************************************************************************************************************
* Storage wrapper functions (get, set, remove)
* Will manage the "remember me" function by using localStorage vs sessionStorage
* **********************************************************************************************************************
*/
export const getStorage = (index) => {
    if(localStorage.getItem(index))
        return localStorage.getItem(index);
    else
        return sessionStorage.getItem(index);
}
export const setStorage = (index, value, rememberMe = false) => {
    if(rememberMe){
        localStorage.setItem(index, value); // set in localStorage
        sessionStorage.removeItem(index); // make sure other session isnt trailing
    }else{
        sessionStorage.setItem(index, value); // set in localStorage
        localStorage.removeItem(index); // make sure other session isnt trailing
    }
}
export const removeStorage = (index) => {
    localStorage.removeItem(index);
    sessionStorage.removeItem(index);
}

/**
* **********************************************************************************************************************
* Authentification by storage function
* Function to help woth recovering a previous authentification
* **********************************************************************************************************************
*/
export const hasPreviousAuthentication = () => {
    return (getStorage('token'));
}

export const removePreviousAuthentication = () => {
    removeStorage('token');
}

export const AuthContext = createContext();

export default class AuthContextProvider extends Component {
    state = {
        userSpace: ACCOUNT,
        user: null,
        product: null,
        userReferrer: null,
    }

    _serverError = async error => {
        throw new Error("server");
    }

    setUser = (userData) => {
        if(userData.first_name === null || userData.last_name === null || userData.email === null || userData.logo === null){
            this.setState({user: null, userReferrer: userData.referrer})
        } else {
            let user = {
                email: userData.email,
                firstName: userData.first_name,
                lastName: userData.last_name,
                logo: userData.logo,
            }
            this.setState({user: user, userReferrer: userData.referrer})
        }
    }

    setProduct = (product) => {
        this.setState({product: product})
    }

    render() {
        return (
            <AuthContext.Provider value={{
                ...this.state,
                setUser: this.setUser,
                setProduct: this.setProduct,
            }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}