import React from "react";
import {
    Collapse,
	Button,
    Row,
	Col,
	FormGroup
} from 'reactstrap';

// Form validation
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import { FormikDateTime, FormikPhoneInput, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import moment from 'moment';

// Cusotm Elements
import Translate from '@spordle/intl-elements';
import Wrapper from "../../components/Wrapper";

// API
import { AxiosCancelAll, AxiosIsCancelled } from "../../api/CancellableAPI";
import { AccountCreation } from "../../contexts/AccountCreationContext";

const letterRegex = /[a-zA-Z]/;
const numberRegex = /\d/;
const zipRegex = [letterRegex, numberRegex, letterRegex, ' ', numberRegex, letterRegex, numberRegex];
const testingZipRegex = new RegExp(zipRegex.join('').replace(/\//gm, ''));

class AccountCreationStep2 extends React.PureComponent {
    static contextType = AccountCreation;

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    componentWillUnmount(){
        AxiosCancelAll()
    }

    scrollToError = () => {
        let elements = document.getElementsByClassName('error');

        if(elements.length !== 1){
            let posArray = [
                document.getElementById('businessOwner').getBoundingClientRect().top + window.pageYOffset,
                document.getElementById('personInCharge').getBoundingClientRect().top + window.pageYOffset,
                document.getElementById('lastAddress').getBoundingClientRect().top + window.pageYOffset,
            ]

            let elementPos = elements[1].getBoundingClientRect().top + window.pageYOffset;
            for (let index = 0; index < posArray.length; index++) {
                let pos = posArray[index];
                let nextPos = posArray[index+1];
                if(nextPos){
                    if(elementPos > pos && elementPos < nextPos){
                        window.scrollTo({
                            top: pos,
                            behavior: 'smooth',
                        });
                        break;
                    }
                } else {
                    window.scrollTo({
                        top: pos,
                        behavior: 'smooth',
                    });
                    break;
                }
            }
            this.hasValidated = false;
        }
    }

    render() {
        const formatMessage = this.props.intl.formatMessage;
        return (
            /*--------------------------------------------------------------------------------*/
            /* Step 2                                                                         */
            /*--------------------------------------------------------------------------------*/
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    email_owner: '',
                    job_title: '',
                    phone_owner: '',
                    date_of_birth: '',
                    percentage_ownership: '',
                    street_owner: '',
                    street2_owner: '',
                    city_owner: '',
                    zip_owner: '',
                    country_owner: 'CA',
                    province_owner: 'QC',
                    years_at_address: '',
                    prev_street_owner: '',
                    prev_street2_owner: '',
                    prev_city_owner: '',
                    prev_zip_owner: '',
                    prev_country_owner: 'CA',
                    prev_province_owner: 'QC',
                    prev_years_at_address: '',
                }}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().required(formatMessage({id: 'error.required'})),
                    last_name: Yup.string().required(formatMessage({id: 'error.required'})),
                    email_owner: Yup.string().required(formatMessage({id: 'error.required'})).email(formatMessage({id: 'error.email'})),
                    job_title: Yup.string().required(formatMessage({id: 'error.required'})),
                    phone_owner: Yup.string().required(formatMessage({id: 'error.required'})).isValidPhoneNumber({id: 'error.phoneFormat'}),
                    date_of_birth: Yup.mixed().required(formatMessage({id: 'error.required'})).test({
                        name: 'date_of_birth_format',
                        message: formatMessage({id: 'error.dateFormat'}),
                        test: (value) => moment.isMoment(value)
                    }).test({
                        name: 'date_of_birth_18',
                        message: formatMessage({id: 'error.birth.minAge'}, {age: 18}),
                        test: (value) => moment().diff(value, 'years') >= 18 // Must be 18 or over
                    }),
                    percentage_ownership: Yup.number().required(formatMessage({id: 'error.required'})).min(0, formatMessage({id: 'error.min'}, {min: 0})).max(100, formatMessage({id: 'error.max'}, {max: 100})),
                    street_owner: Yup.string().required(formatMessage({id: 'error.required'})),
                    city_owner: Yup.string().required(formatMessage({id: 'error.required'})),
                    zip_owner: Yup.string().required(formatMessage({id: 'error.required'})).matches(testingZipRegex, formatMessage({id: 'error.zipFormat'})),
                    country_owner: Yup.string().required(formatMessage({id: 'error.required'})),
                    province_owner: Yup.string().required(formatMessage({id: 'error.required'})),
                    years_at_address: Yup.number().required(formatMessage({id: 'error.required'})).min(0, formatMessage({id: 'error.min'}, {min: 0})),
                    /* THESE FILEDS HAVE VALIDATION ONLY WHEN years_at_address < 10 */
                    prev_street_owner: Yup.string().test('prev_street_owner_required', formatMessage({id: 'error.required'}), function (value){
                        if(this.parent.years_at_address < 10){
                            return (value?.length ?? 0) !== 0;
                        }
                        return true;
                    }),
                    prev_city_owner: Yup.string().test('prev_city_owner_required', formatMessage({id: 'error.required'}), function (value){
                        if(this.parent.years_at_address < 10){
                            return (value?.length ?? 0) !== 0;
                        }
                        return true;
                    }),
                    prev_zip_owner: Yup.string().test('prev_zip_owner_required', formatMessage({id: 'error.required'}), function (value){
                        if(this.parent.years_at_address < 10){
                            return (value?.length ?? 0) !== 0;
                        }
                        return true;
                    }).test('prev_zip_owner_matches', formatMessage({id: 'error.zipFormat'}), function(value){
                        if(this.parent.years_at_address < 10){
                            return testingZipRegex.test(value);
                        }
                        return true;
                    }),
                    prev_country_owner: Yup.string().test('prev_country_owner_required', formatMessage({id: 'error.required'}), function (value){
                        if(this.parent.years_at_address < 10){
                            return (value?.length ?? 0) !== 0;
                        }
                        return true;
                    }),
                    prev_province_owner: Yup.string().test('prev_province_owner_required', formatMessage({id: 'error.required'}), function (value){
                        if(this.parent.years_at_address < 10){
                            return (value?.length ?? 0) !== 0;
                        }
                        return true;
                    }),
                    prev_years_at_address: Yup.number().test('prev_years_at_address_required', formatMessage({id: 'error.required'}), function (value){
                        if(this.parent.years_at_address < 10){
                            return (value ?? 0) !== 0;
                        }
                        return true;
                    }).test('prev_years_at_address_min', formatMessage({id: 'error.min'}, {min: 0}), function (value){
                        if(this.parent.years_at_address < 10){
                            return value >= 0;
                        }
                        return true;
                    }),
                })}
                onSubmit={(values, { setSubmitting, setStatus, setFieldError }) => {
                    console.log("onSubmit")
                    console.log(values)
                    setStatus();
                    values.date_of_birth = moment(values.date_of_birth).format('YYYY-MM-DD');
                    this.context.step2(values, this.props)
                        .then(() => {
                            this.props.nextStep();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false);
                                if(Array.isArray(error)){
                                    error.forEach(fieldError => {
                                        switch (fieldError.field) {
                                            case 'email':
                                                setFieldError('email_owner', fieldError.error);
                                                break;
                                            case 'percentageOwnership':
                                                setFieldError('percentage_ownership', fieldError.error);
                                                break;
                                            case 'currentAddress.zip':
                                                setFieldError('zip_owner', fieldError.error);
                                                break;
                                            case 'currentAddress.yearsAtAddress':
                                                setFieldError('years_at_address', fieldError.error);
                                                break;
                                            case 'previousAddress.zip':
                                                setFieldError('prev_zip_owner', fieldError.error);
                                                break;
                                            case 'previousAddress.yearsAtAddress':
                                                setFieldError('prev_years_at_address', fieldError.error);
                                                break;
                                            default:
                                                break;
                                        }
                                    });
                                }
                                else{
                                    console.error(error);
                                    setStatus(error.message);
                                }
                                this.scrollToError();
                            }
                        });
                }}
            >
                {formik => {
                    if(this.hasValidated){
                        this.scrollToError();
                    }
                    if(formik.isValidating){
                        this.hasValidated = true;
                    }
                    return (
                        <Form id='step2_form'>
                            {/* This div is needed for the scrollToError to work */}
                            <div className="error d-none"></div>
                            {/* PERSON IN CHARGE PERSONAL INFO */}
                            <div id="businessOwner" className="h3 mt-2 mb-4 font-bold text-center"><Translate id="LEGEND_BUSINESS_OWNER"/></div>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText trim label='FIRST_NAME' name="first_name" id="first_name" autoComplete="new-first_name"/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText trim label='LAST_NAME' name="last_name" id="last_name" autoComplete="new-last_name"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText type='email' trim label='EMAIL' name="email_owner" id="email_owner" autoComplete="new-email" helper='VALID_EMAIL'/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText trim label='JOB_TITLE' name="job_title" id="job_title" autoComplete="new-job_title"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='3'>
                                        <FormikPhoneInput label="PHONE_OWNER" name="phone_owner" id="phone_owner" />
                                    </Col>
                                    <Col md='3'>
                                        <FormikDateTime dateFormat='YYYY-MM-DD' name='date_of_birth' label='DATE_OF_BIRTH' inputProps={{ id: 'create-date_of_birth', autoComplete: "none" }} timeFormat={false} />
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText type='number' min='0' max='100' label='PERCENTAGE_OWNERSHIP' name="percentage_ownership" id="percentage_ownership" autoComplete="new-percentage_ownership"/>
                                    </Col>
                                </Row>
                            </FormGroup>

                            {/* PERSON IN CHARGE ADDRESS */}
                            <div id="personInCharge" className="h3 mt-5 mb-4 font-bold text-center"><Translate id="LEGEND_BUSINESS_OWNER_CURR_ADDRESS"/></div>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText label='STREET' trim name="street_owner" id="street_owner" autoComplete="new-street_owner"/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText label='STREET_2' trim name="street2_owner" id="street2_owner" autoComplete="new-street2_owner"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='6'>
                                        <FormikInputText label='CITY' trim name="city_owner" id="city_owner" autoComplete="new-city_owner"/>
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText label='ZIP' trim name="zip_owner" id="zip_owner" autoComplete="new-zip_owner"/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md='3'>
                                        <FormikSelect
                                            name="country_owner" id="country_owner" label='COUNTRY'
                                            search={false}
                                            placeholder='SELECT_ONE'
                                            defaultData={[
                                                { label: 'Canada', value: 'CA', translateLabel: true },
                                            ]}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                    <Col md='3'>
                                        <FormikSelect
                                            name="province_owner" id="province_owner" label='STATE'
                                            search={false}
                                            placeholder='SELECT_ONE'
                                            defaultData={[
                                                { label: 'Alberta', value: 'AB', translateLabel:false },
                                                { label: 'BRITISH_COLUMBIA', value: 'BC', translateLabel:true },
                                                { label: 'Manitoba', value: 'MB', translateLabel:false },
                                                { label: 'NEW_BRUNSWICK', value: 'NB', translateLabel:true },
                                                { label: 'NEWFOUNDLAND', value: 'NL', translateLabel:true },
                                                { label: 'NOVA_SCOTIA', value: 'NS', translateLabel:true },
                                                { label: 'NORTHWEST_TERRITORIES', value: 'NT', translateLabel:true },
                                                { label: 'Nunavut', value: 'NU', translateLabel:false },
                                                { label: 'Ontario', value: 'ON', translateLabel:false },
                                                { label: 'PRINCE_EDWARD_ISLAND', value: 'PE', translateLabel:true },
                                                { label: 'QUEBEC', value: 'QC', translateLabel:true },
                                                { label: 'Saskatchewan', value: 'SK', translateLabel:false },
                                                { label: 'Yukon', value: 'YT', translateLabel:false },
                                            ]}
                                            renderOption={(option) => <Translate id={option.option.label} />}
                                            loadingStatus="success"
                                        />
                                    </Col>
                                    <Col md='6'>
                                        <FormikInputText type='number' min='0' label='YEARS_AT_ADDRESS' name="years_at_address" id="years_at_address" autoComplete="new-years_at_address"/>
                                    </Col>
                                </Row>
                            </FormGroup>

                            {/* LAST ADDRESS INFO */}
                            <Collapse isOpen={parseInt(formik.values.years_at_address) < 10}>
                                <Row>
                                    <Col xs="12" sm="12" md={{ size: 10, offset: 1 }} lg={{ size: 10, offset: 1 }}/>
                                </Row>
                                <div id="lastAddress" className="h3 mt-5 mb-4 font-bold text-center"><Translate id="LEGEND_BUSINESS_OWNER_PREV_ADDRESS"/></div>
                                <FormGroup>
                                    <Row>
                                        <Col md='6'>
                                            <FormikInputText label='STREET' trim name="prev_street_owner" id="prev_street_owner" autoComplete="new-prev_street_owner"/>
                                        </Col>
                                        <Col md='6'>
                                            <FormikInputText label='STREET_2' trim name="prev_street2_owner" id="prev_street2_owner" autoComplete="new-prev_street2_owner"/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md='6'>
                                            <FormikInputText label='CITY' trim name="prev_city_owner" id="prev_city_owner" autoComplete="new-prev_city_owner"/>
                                        </Col>
                                        <Col md='6'>
                                            <FormikInputText label='ZIP' trim name="prev_zip_owner" id="prev_zip_owner" autoComplete="new-prev_zip_owner"/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md='3'>
                                            <FormikSelect
                                                name="prev_country_owner" id="prev_country_owner" label='COUNTRY'
                                                search={false}
                                                placeholder='SELECT_ONE'
                                                defaultData={[
                                                    { label: 'Canada', value: 'CA', translateLabel: true },
                                                ]}
                                                renderOption={(option) => <Translate id={option.option.label} />}
                                                loadingStatus="success"
                                            />
                                        </Col>
                                        <Col md='3'>
                                            <FormikSelect
                                                name="prev_province_owner" id="prev_province_owner" label='STATE'
                                                search={false}
                                                placeholder='SELECT_ONE'
                                                defaultData={[
                                                    { label: 'Alberta', value: 'AB', translateLabel:false },
                                                    { label: 'BRITISH_COLUMBIA', value: 'BC', translateLabel:true },
                                                    { label: 'Manitoba', value: 'MB', translateLabel:false },
                                                    { label: 'NEW_BRUNSWICK', value: 'NB', translateLabel:true },
                                                    { label: 'NEWFOUNDLAND', value: 'NL', translateLabel:true },
                                                    { label: 'NOVA_SCOTIA', value: 'NS', translateLabel:true },
                                                    { label: 'NORTHWEST_TERRITORIES', value: 'NT', translateLabel:true },
                                                    { label: 'Nunavut', value: 'NU', translateLabel:false },
                                                    { label: 'Ontario', value: 'ON', translateLabel:false },
                                                    { label: 'PRINCE_EDWARD_ISLAND', value: 'PE', translateLabel:true },
                                                    { label: 'QUEBEC', value: 'QC', translateLabel:true },
                                                    { label: 'Saskatchewan', value: 'SK', translateLabel:false },
                                                    { label: 'Yukon', value: 'YT', translateLabel:false },
                                                ]}
                                                renderOption={(option) => <Translate id={option.option.label} />}
                                                loadingStatus="success"
                                            />
                                        </Col>
                                        <Col md='6'>
                                            <FormikInputText type='number' min='0' label='YEARS_AT_ADDRESS_BEFORE' name="prev_years_at_address" id="prev_years_at_address" autoComplete="new-prev_years_at_address"/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Collapse>

                            {/* {formik.status &&
                                <div className={'alert alert-danger'}><Translate id={`error.api.step2.${formik.status}`}/></div>
                            } */}
                            <div className="mt-4 text-right">
                                <Button color="info" className="btn btn-info" type="submit" disabled={formik.isSubmitting}><Translate id="NEXT_STEP"/></Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        );
    }
}

export default (props) => <Wrapper {...props} JsxElement={AccountCreationStep2}/>;