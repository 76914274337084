import React, { useContext } from "react";
import { Fade, Container } from "reactstrap";
import queryString from 'query-string';

// Components
import AccountCreationStep1 from "../../components/accountCreation/AccountCreationStep1";
import AccountCreationStep2 from "../../components/accountCreation/AccountCreationStep2";
import AccountCreationStep3 from "../../components/accountCreation/AccountCreationStep3";
import AccountCreationStep4 from "../../components/accountCreation/AccountCreationStep4";
import AccountCreationStep5 from "../../components/accountCreation/AccountCreationStep5";
import AccountCreationStep6 from "../../components/accountCreation/AccountCreationStep6";

// Language
import Translate from '@spordle/intl-elements';
import { LOCALES } from '../../i18n';

// API
import { AccountCreation as AccountCreationContext } from "../../contexts/AccountCreationContext";
import { AxiosCancelAll, AxiosIsCancelled } from "../../api/CancellableAPI";

import { I18nContext } from '../../contexts/I18nContext'
import { AuthContext } from '../../contexts/AuthContext'

const customStyle = {
    'stepTitle' : {
        color:"#B8C1D0"
    }
};

const initialState = {
	activeStep: 0,
	productName: '',
	validToken: true
};

const AccountCreation = props => <AccountCreationContent {...props} I18nContext={useContext(I18nContext)} AuthContext={useContext(AuthContext)}/>
export default AccountCreation;

class AccountCreationContent extends React.Component {
	static contextType = AccountCreationContext;

	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidMount() {
		/*window.onbeforeunload = function(){
			return translate('misc.beforeUnload');
		};*/

		// Trigger initializing
		this.setInitStep();
	}

	componentWillUnmount(){
		AxiosCancelAll();
	}

	setCurrentLang = (lang) => {
		if(lang === 'fr')
			this.props.I18nContext.setLocale(LOCALES.FRENCH);
		else
			this.props.I18nContext.setLocale(LOCALES.ENGlISH);
	}

    setCurrentAccountData = (data) => {
		this.setCurrentLang(data.lang);
		this.setState({
			activeStep: parseInt(data.step),
			lang: data.lang,
			product: data.product,
			productName: data.product_name,
			ref_id: data.ref_id,
			tok: data.tok,
			user_data: data?.user_data
		});
		this.props.AuthContext.setProduct({product: data.product, productName: data.product_name});
		if(data.user_data)
			this.props.AuthContext.setUser(data.user_data);
	}

	setInitStep() {
		let parsedUrlParam = queryString.parse(this.props.location.search);
		this.setCurrentLang(parsedUrlParam.lang);

		this.context.validateAccessToken(parsedUrlParam.lang, parsedUrlParam.ref_id, parsedUrlParam.tok, parsedUrlParam.product)
			.then((response) => {
				//handle success
				let data = response.data;
				if(data.status){
					if(data.return.token_is_valid)
						this.setCurrentAccountData(data.return);
					else
						this.setState({validToken: false});
				}else{
					// Managed error
					this.setState({validToken: false});
				}
			})
			.catch((response) => {
				//handle error
				console.log(response);
				if(!AxiosIsCancelled(response.message))
					this.props.history.push('/home');
			});
	}

	nextStep() {
		// Wrong
		//this.setState({
		//	activeStep: this.state.activeStep + 1
		//});

		// Correct => https://reactjs.org/docs/state-and-lifecycle.html#state-updates-may-be-asynchronous
		this.setState((state) => ({
			activeStep: state.activeStep + 1
		}));
	}

	render() {
		return (
			<Container className="mx-auto">

				<Fade in={(this.state.activeStep > 0 && this.state.activeStep <= 5)} >
					<div className="h3 mb-5 text-uppercase font-bold text-center" style={customStyle.stepTitle}><Translate id="stepTitle" values={{step: this.state.activeStep}} /></div>
				</Fade>

				{ this.state.validToken === false &&
					<Fade in={this.state.validToken === false}>
						<div className="h4 mb-5 text-danger font-bold text-center"><Translate id="CONTACT_SPORDLE" /></div>
					</Fade>
				}

				<Fade in={this.state.activeStep === 1}>
					{ this.state.activeStep === 1 && <AccountCreationStep1 {...this.state} nextStep={() => this.nextStep()} /> }
				</Fade>
				<Fade in={this.state.activeStep === 2}>
					{ this.state.activeStep === 2 && <AccountCreationStep2 {...this.state} nextStep={() => this.nextStep()} /> }
				</Fade>
				<Fade in={this.state.activeStep === 3}>
					{ this.state.activeStep === 3 && <AccountCreationStep3 {...this.state} nextStep={() => this.nextStep()} /> }
				</Fade>
				<Fade in={this.state.activeStep === 4}>
					{ this.state.activeStep === 4 && <AccountCreationStep4 {...this.state} nextStep={() => this.nextStep()} /> }
				</Fade>
				<Fade in={this.state.activeStep === 5}>
					{ this.state.activeStep === 5 && <AccountCreationStep5 {...this.state} nextStep={() => this.nextStep()} /> }
				</Fade>
				<Fade in={this.state.activeStep === 6}>
					{ this.state.activeStep === 6 && <AccountCreationStep6 {...this.state} /> }
				</Fade>

			</Container>
		);
	}
}