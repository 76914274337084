import { useContext, useEffect, useState } from "react";
import {
    Button,
    Row,
    Col,
    FormGroup,
    CardBody,
    Fade
} from 'reactstrap';
import { formatSelectData } from "@spordle/spordle-select";
// Translate function (i18n)


import { FirstName, LastName, Email, AddressStreet, City, ZipCode, Country, State } from "@spordle/mini-gateway";
import Translate from "@spordle/intl-elements";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { MiniGatewayContext } from "@spordle/mini-gateway/dist/src/MiniGatewayProvider";
import { getCountries } from "../../../api/client/countries";
import { I18nContext } from "../../../contexts/I18nContext";

const PaymentBilling = () => {
    const gateway = useContext(MiniGatewayContext);
    const {
        nextStep,
        previousStep,
        completedSteps,
    } = useContext(PaymentContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ countries, setCountries ] = useState([]);
    const provinces = countries.find(({ code }) => code === gateway.formik.values.country)?.sub_divisions || [];


    const updateCountries = () => {
        getCountries()
            .then((countries) => {
                setCountries(countries);
            })
            .catch((e) => {
                console.error(e);
            })
    }

    useEffect(() => {
        updateCountries();
    }, []);

    return (
        <Fade>

            <CardBody>

                <h1 className="h3 mb-3 font-bold"><Translate id="payment.billing.form.title" /></h1>
                <FormGroup>
                    <Row>
                        <Col sm="6">
                            <FirstName label='payment.billing.form.label.firstName' />
                        </Col>
                        <Col sm="6">
                            <LastName label='payment.billing.form.label.lastName' />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Email label='payment.billing.form.label.email' />
                </FormGroup>

                <FormGroup>
                    <AddressStreet label='payment.billing.form.label.street' />
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col md='8'>
                            <City label='payment.billing.form.label.city' />
                        </Col>
                        <Col md='4'>
                            <ZipCode label='payment.billing.form.label.zip' />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col md='6'>
                            <Country
                                label={<Translate id='COUNTRY' />}
                                noOptionLayout={<Translate id='FORMIK_SELECT_NO_OPTION' />}
                                placeholder='SELECT_ONE'
                                renderOption={({ option }) => {
                                    if(option.isGroup){
                                        return <Translate id='misc.select.suggested' />;
                                    }

                                    return option.i18n?.[getGenericLocale()]?.name || option.code
                                }}
                                searchKeys={[
                                    `i18n.${getGenericLocale()}.name`,
                                ]}
                                virtualizationThreshold={100}
                                onOptionSelected={([ val ]) => {
                                    const newCountry = countries.find(({ code }) => code === val);
                                    gateway.formik.setFieldValue('province', newCountry.sub_divisions.length > 0 ? '' : 'NONE');
                                }}
                                options={formatSelectData(
                                    countries,
                                    {
                                        getValue: (country) => country.code,
                                        getLabel: (country) => country.code,
                                        getGroupId: (country) => [ "CA", "US" ].includes(country.code) ? 'suggested' : undefined,
                                        newGroupIndexes: {
                                            0: {
                                                code: 'Suggested Group',
                                                groupId: "suggested",
                                                label: 'misc.select.suggested',
                                                isGroup: true,
                                            },
                                        },
                                    },
                                )}
                            />
                        </Col>
                        {Array.isArray(provinces) && provinces.length > 0 &&
                            <Col md='6'>
                                <State
                                    label={<Translate id='STATE' />}
                                    noOptionLayout={<Translate id='FORMIK_SELECT_NO_OPTION' />}
                                    placeholder='SELECT_ONE'
                                    renderOption={({ option }) => {
                                        return option.i18n?.[getGenericLocale()]?.name || option.code
                                    }}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    options={(provinces || []).map((province) => ({
                                        ...province,
                                        value: province.code,
                                        label: province.code,
                                    }))}
                                />
                            </Col>
                        }
                    </Row>
                </FormGroup>
                <div className="text-right mt-4">
                    {!completedSteps ?
                        <Button
                            color="outline-secondary"
                            className="ml-3"
                            size="lg"
                            onClick={() => {
                                previousStep();
                            }}
                        >
                            <Translate id='misc.action.back' />
                        </Button>
                        :
                        null
                    }
                    <Button
                        color="primary"
                        className="ml-3"
                        size="lg"
                        onClick={() => {
                            gateway.validateDisplayedFields()
                                .then((hasError) => {
                                    if(!hasError)
                                        nextStep();
                                });
                        }}
                    >
                        <Translate id="misc.action.next" />
                    </Button>
                </div>
            </CardBody>
        </Fade>
    );
}
export default PaymentBilling;