import React from "react";
import {
	Collapse,
    Row,
	FormGroup,
    Button,
    Col
} from 'reactstrap';
import CapsLock from '@spordle/capslock';
import Wrapper from "../../components/Wrapper";

// Form validation
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import { AccountCreation } from "../../contexts/AccountCreationContext";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";

// Cusotm Elements
import Translate from '@spordle/intl-elements';
import { AxiosCancelAll, AxiosIsCancelled } from "../../api/CancellableAPI";

class AccountCreationStep5 extends React.PureComponent {
    static contextType = AccountCreation;

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    componentWillUnmount(){
		AxiosCancelAll();
	}

    render() {
        const formatMessage = this.props.intl.formatMessage;
        return (
            /*--------------------------------------------------------------------------------*/
            /* Step 5                                                                         */
            /*--------------------------------------------------------------------------------*/
            <Formik
                initialValues={{
                    user_name: '',
                    password: '',
                    account_email: '',
                    recovery_question: '',
                    answer: '',
                }}
                validationSchema={Yup.object().shape({
                    user_name: Yup.string().required(formatMessage({id: 'error.required'})).min(2, formatMessage({id: 'error.length.min'}, {min: 2})).max(64, formatMessage({id: 'error.length.max'}, {max: 64}))
                        // Only lower case letters
                        .test({
                            name: 'user_name_on_min',
                            message: formatMessage({id: 'error.isLower'}),
                            test: (value) => {
                                for (let index = 0; value && index < value.length; index++) {
                                    if(/[a-zA-Z]/.test(value.charAt(index))){
                                        // Is a letter
                                        if(!/[a-z]/.test(value.charAt(index))){
                                            return false;
                                        }
                                    }
                                }
                                return true;
                            }
                        })
                        // No spaces
                        .test({
                            name: 'user_name_noSpace',
                            message: formatMessage({id: 'error.noSpaces'}),
                            test: (value) => {
                                for (let index = 0; value && index < value.length; index++) {
                                    if(/\s/.test(value.charAt(index))){
                                        return false;
                                    }
                                }
                                return true;
                            }
                        }),
                    password: Yup.string().required(formatMessage({id: 'error.required'})).min(8, formatMessage({id: 'error.length.min'}, {min: 8}))
                        // At least one upper case letter
                        .test({
                            name: 'password_one_maj',
                            message: formatMessage({id: 'error.oneMaj'}),
                            test: (value) => {
                                for (let index = 0; value && index < value.length; index++) {
                                    if(/[A-Z]/.test(value.charAt(index))){
                                        return true;
                                    }
                                }
                                return false;
                            }
                        })
                        // At least one lower case letter
                        .test({
                            name: 'password_one_min',
                            message: formatMessage({id: 'error.oneMin'}),
                            test: (value) => {
                                for (let index = 0; value && index < value.length; index++) {
                                    if(/[a-z]/.test(value.charAt(index))){
                                        return true;
                                    }
                                }
                                return false;
                            }
                        }),
                    account_email: Yup.string().required(formatMessage({id: 'error.required'})).email(formatMessage({id: 'VALID_EMAIL'})),
                    recovery_question: Yup.string().required(formatMessage({id: 'error.required'})),
                    answer: Yup.string().required(formatMessage({id: 'error.required'})),
                })}
                onSubmit={(values, { setSubmitting, setStatus, setFieldError }) => {
                    setStatus();

                    this.context.step5(this.props.product, this.props.ref_id, values)
                        .then(() => {
                            this.props.nextStep();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false);
                                if(Array.isArray(error)){
                                    error.forEach(fieldError => {
                                        switch (fieldError.field) {
                                            case 'userName':
                                                setFieldError('user_name', fieldError.error);
                                                break;
                                            case 'email':
                                                setFieldError('account_email', fieldError.error);
                                                break;
                                            case 'recoveryQuestion.answer':
                                                setFieldError('answer', fieldError.error);
                                                break;
                                            case 'password':
                                                setFieldError('password', fieldError.error);
                                                break;
                                            default:
                                                break;
                                        }
                                    });
                                }
                                else{
                                    console.error(error);
                                    setStatus(error.message);
                                }
                            }
                        });
                }}
            >
                {formik => (
                    <Form id='step5_form' style={{whiteSpace: 'pre-line'}}>
                        <div className="h3 mt-2 mb-2 font-bold text-center"><Translate id="LEGEND_CREATE_USER"/></div>
                        <p className="mb-5 text-center"><Translate id="LEGEND_CREATE_USER_DESC"/></p>
                        <FormGroup>
                            <Row>
                                <Col md='6'>
                                    <FormikInputText trim label='USER_NAME' name="user_name" id="user_name" autoComplete="new-user_name" maxLength="64" minLength="2" helper='SPECS_USERNAME'/>
                                </Col>
                                <Col md='6'>
                                    <FormikInputText type='password' trim label='PASSWORD' name="password" id="password" autoComplete="new-password" minLength="8" helper='SPECS_PASSWORD'/>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <FormikInputText type='email' trim label='EMAIL' name="account_email" id="account_email" autoComplete="new-account_email"/>
                        </FormGroup>

                        <FormGroup>
                            <FormikSelect
                                name="recovery_question" id="recovery_question" label='RECOVERY_QUESTION'
                                search={false}
                                placeholder='SELECT_ONE'
                                defaultData={[
                                    { label: 'question_oldest_sibling', value: '1', translateLabel:false },
                                    { label: 'question_youngest_sibling', value: '2', translateLabel:true },
                                    { label: 'question_first_car', value: '3', translateLabel:false },
                                    { label: 'question_bank_account', value: '4', translateLabel:true },
                                    { label: 'question_bank_city', value: '5', translateLabel:true },
                                    { label: 'question_first_pet', value: '6', translateLabel:true },
                                    { label: 'question_mobile_provider', value: '7', translateLabel:true },
                                    { label: 'question_sum_birth', value: '8', translateLabel:false },
                                    { label: 'question_business_city_year', value: '9', translateLabel:false },
                                    { label: 'question_high_school_graduate', value: '10', translateLabel:true },
                                ]}
                                renderOption={(option) => <Translate id={option.option.label} />}
                                loadingStatus="success"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormikInputText trim label='ANSWER' name="answer" id="answer" autoComplete="new-answer"/>
                        </FormGroup>

                        <CapsLock>
                            {isActive => (
                                <Collapse isOpen={isActive}>
                                    <div className={'alert alert-warning mb-0'}><i className="mr-1 mdi mdi-apple-keyboard-caps"></i><Translate id="warning.capsLock"/></div>
                                </Collapse>
                            )}
                        </CapsLock>

                        {formik.status &&
                            <div className={'alert alert-danger'}><Translate id='error.api'/></div>
                        }
                        <div className="mt-4 text-right">
                            <Button color="info" type="submit" disabled={formik.isSubmitting}><Translate id="NEXT_STEP"/></Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}
export default (props) => <Wrapper {...props} JsxElement={AccountCreationStep5}/>;