import axios from 'axios';
import { activeCalls } from './CancellableAPI';

const PROD_URL = "https://api.payment.spordle.com/paysafe/v1/";
const STAGE_URL = "https://api.gateway.spordle.dev/paysafe/v1/";
const DEV_URL = "https://api.gateway.spordle.dev/paysafe/v1/";
export const API_KEY = process.env.REACT_APP_GATEWAY_API_KEY; // Same API Key for Prod and Dev

var current_url = DEV_URL;
if(process.env.REACT_APP_ENVIRONMENT === 'prod')
    current_url = PROD_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'stage')
    current_url = STAGE_URL;

const CancelToken = axios.CancelToken;
const PaymentAPI = axios.create({
    baseURL: current_url,
    headers: {
        'SPRDL-API-KEY': API_KEY,
        common: {
            'Content-Type': 'application/json',
            'Accept-Language': 'fr;en', // All supported languages
        },
        patch: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        put: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    },
    validateStatus: (status) => {
        return status; // Let all error status go through to catch in response
    },
});

PaymentAPI.interceptors.request.use(function(config){
    config.cancelToken = new CancelToken(function executor(c){
        // An executor function receives a cancel function as a parameter
        activeCalls.push(c);
    });
    return config;
});

export default PaymentAPI;