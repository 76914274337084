import React, { Fragment } from 'react';
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';

import { LOCALES } from './locales';
import messages from './messages';

const cache = createIntlCache();

const Provider = ({children, locale = LOCALES.ENGLISH}) => {
    // Need to be here to change values when user changes language
    // reactSwalInit(createIntl({
    //     locale: locale,
    //     messages: messages[locale],
    // }, cache));


    return (
    <IntlProvider
        locale={locale}
        textComponent={Fragment}
        messages={messages[locale]}
    >
        {children}
    </IntlProvider>
)};

export default Provider;