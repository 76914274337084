export const getUrlParameter = (sParam) => {
    const sURLVariables = window.location.search.substring(1).split('&');
    let sParameterName, i;

    for(i = 0; i < sURLVariables.length; i++){
        sParameterName = sURLVariables[i].split('=');

        if(sParameterName[0] === sParam){
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}