export default {
    'countries.CA.AB'                    : 'Alberta',
    'countries.CA.BC'                    : 'Colombie britannique',
    'countries.CA.MB'                    : 'Manitoba',
    'countries.CA.NB'                    : 'Nouveau-Brunswick',
    'countries.CA.NL'                    : 'Terre-Neuve-et-Labrador',
    'countries.CA.NS'                    : 'Nouvelle-Écosse',
    'countries.CA.NU'                    : 'Nunavut',
    'countries.CA.NT'                    : 'Territoires du nord-ouest',
    'countries.CA.ON'                    : 'Ontario',
    'countries.CA.PE'                    : 'Île-du-Prince-Édouard',
    'countries.CA.QC'                    : 'Québec',
    'countries.CA.SK'                    : 'Saskatchewan',
    'countries.CA.YT'                    : 'Yukon',
}