import MiniGatewayProvider from "@spordle/mini-gateway/dist/src/MiniGatewayProvider";
import PaymentContextProvider from "../../contexts/PaymentContext";
import PaymentWizard from "./PaymentWizard";
import { API_KEY } from "../../api/APIGatewayPayment";


const Payment = (props) => {
    const paymentToken = props.match?.params?.token;

    return (
        <PaymentContextProvider {...props} paymentToken={paymentToken}>
            <MiniGatewayProvider
                cartId={paymentToken}
                apiKey={API_KEY}
            >
                <PaymentWizard />
            </MiniGatewayProvider>
        </PaymentContextProvider>
    );

}

export default Payment;
