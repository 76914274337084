import { LOCALES } from "../locales";

import home from "./en-CA/home";
import countries from "./en-CA/countries";
import titles from "./en-CA/titles";
import warnings from "./en-CA/warnings";
import errors from "./en-CA/errors";
import misc from "./en-CA/misc";
import accountCreation from "./en-CA/accountCreation";
import payment from "./en-CA/payment";
import footer from "./en-CA/footer";

export default{
    [LOCALES.ENGLISH] : {
        ...home,
        ...countries,
        ...titles,
        ...warnings,
        ...errors,
        ...misc,
        ...accountCreation,
        ...payment,
        ...footer,
    },
}