import React from "react";
import {
	Button,
    Row,
    FormGroup,
    Col
} from 'reactstrap';
import Wrapper from "../../components/Wrapper";

// Form validation
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import { FormikInputNumber } from "@spordle/formik-elements";

// Translate function (i18n)
import Translate from '@spordle/intl-elements';

// API
import { AccountCreation } from "../../contexts/AccountCreationContext";
import { AxiosCancelAll, AxiosIsCancelled } from "../../api/CancellableAPI";

class AccountCreationStep3 extends React.PureComponent {
    static contextType = AccountCreation

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    componentWillUnmount(){
		AxiosCancelAll();
	}

    render() {
        const formatMessage = this.props.intl.formatMessage;
        return (
            /*--------------------------------------------------------------------------------*/
            /* Step 3                                                                         */
            /*--------------------------------------------------------------------------------*/
            <Formik
                initialValues={{
                    account_number: '',
                    transit_number: '',
                    institution_id: '',
                }}
                validationSchema={Yup.object().shape({
                    account_number: Yup.string().required(formatMessage({id: 'error.required'})).min(5, formatMessage({id: 'error.min'}, {min: 5})).max(12, formatMessage({id: 'error.max'}, {max: 12})),
                    transit_number: Yup.string().required(formatMessage({id: 'error.required'})).length(5, formatMessage({id: 'error.length'}, {length: 5})),
                    institution_id: Yup.string().required(formatMessage({id: 'error.required'})).length(3, formatMessage({id: 'error.length'}, {length: 3})),
                })}
                onSubmit={(values, { setSubmitting, setStatus, setFieldError }) => {
                    setStatus();

                    this.context.step3(values, this.props)
                        .then(() => {
                            this.props.nextStep()
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                setSubmitting(false);
                                if(Array.isArray(error)){
                                    error.forEach(fieldError => {
                                        switch (fieldError.field) {
                                            case 'accountNumber':
                                                setFieldError('account_number', fieldError.error);
                                                break;
                                            case 'transitNumber':
                                                setFieldError('transit_number', fieldError.error);
                                                break;
                                            case 'institutionId':
                                                setFieldError('institution_id', fieldError.error);
                                                break;
                                            default:
                                                break;
                                        }
                                    });
                                }
                                else{
                                    console.error(error);
                                    setStatus(error.message);
                                }
                            }
                        });
                }}
            >
                {formik => (
                    <Form id='step3_form'>
                        <div className="h3 mt-2 mb-4 font-bold text-center"><Translate id="LEGEND_BANK_ACCOUNT_DETAILS"/></div>
                        <FormGroup>
                            <Row>
                                <Col md='4'>
                                    <FormikInputNumber allowNegative={false} maxLength='12' allowLeadingZeros label="ACCOUNT_NUMBER" name="account_number" id="account_number" autoComplete="new-account_number" helper='ACCOUNT_NUMBER_HELPER'/>
                                </Col>
                                <Col md='4'>
                                    <FormikInputNumber allowNegative={false} maxLength='5' allowLeadingZeros label="TRANSIT_NUMBER" name="transit_number" id="transit_number" autoComplete="new-transit_number" helper='TRANSIT_NUMBER_HELPER'/>
                                </Col>
                                <Col md='4'>
                                    <FormikInputNumber allowNegative={false} maxLength='3' allowLeadingZeros label="INSTITUTION_ID" name="institution_id" id="institution_id" autoComplete="new-institution_id" helper='INSTITUTION_ID_HELPER'/>
                                </Col>
                            </Row>
                        </FormGroup>

                        {formik.status &&
                            <div className={'alert alert-danger'}><Translate id='error.api'/></div>
                        }
                        <div className="mt-4 text-right">
                            <Button color="info" className="btn btn-info" type="submit" disabled={formik.isSubmitting}><Translate id="NEXT_STEP"/></Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default (props) => <Wrapper {...props} JsxElement={AccountCreationStep3}/>;